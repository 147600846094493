import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Container, Row, Col, Form, Button, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { getAllWeeklyTest } from '../../../../actions/weeklyTestActions'
import { Link, useNavigate } from 'react-router-dom'
import { NeverAlways } from '../../../../components/never-always'

export default function Weeklyresult() {
	const { t } = useTranslation()
	const {client} = useAuth()
	const [miEstado, setMiestado] = useState('')
	const [salud, setSalud] = useState('')
	const [exito, setExito] = useState('')
	const [compania, setCompania] = useState('')
	const [esfuerzo, setEsfuerzo] = useState('')
    const [displayElements, setDisplayElements] = useState(false)
	const {tests} = useSelector(state=>state.weeklyTest)
	const dispatch = useDispatch()
	const navigate = useNavigate()

    const [loadingWeekly, setLoadingWeekly] = useState(true)

	useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
		if(client?.id){
            setTimeout(function(){
                dispatch(getAllWeeklyTest(client?.id))
            }, 400);
            setTimeout(function(){
                setLoadingWeekly(false)
            }, 600);
		}
	}, [dispatch, client])

	useEffect(()=>{
		if(tests?.length>0){
			const resultado = calculoInitial(tests[tests.length-1].responses)

			// PROMEDIO TOTAL
			if(resultado.promedioTotal<2){
				setMiestado('crisis')
			}else{
				if(resultado.promedioTotal<3){
					setMiestado('challenge')
				}else{
					if(resultado.promedioTotal<3.5){
						setMiestado('flow')
					}else{
						setMiestado('wow')
					}
				}
			}

			// PROMEDIO A
			if(resultado.promedioA<2){
				setSalud('crisis')
			}else{
				if(resultado.promedioA<3){
					setSalud('challenge')
				}else{
					if(resultado.promedioA<3.5){
						setSalud('flow')
					}else{
						setSalud('wow')
					}
				}
			}

			// PROMEDIO B
			if(resultado.promedioB<2){
				setExito('crisis')
			}else{
				if(resultado.promedioB<3){
					setExito('challenge')
				}else{
					if(resultado.promedioB<3.5){
						setExito('flow')
					}else{
						setExito('wow')
					}
				}
			}

			// PROMEDIO C
			if(resultado.promedioC<2){
				setCompania('crisis')
			}else{
				if(resultado.promedioC<3){
					setCompania('challenge')
				}else{
					if(resultado.promedioC<3.5){
						setCompania('flow')
					}else{
						setCompania('wow')
					}
				}
			}
		
			// PROMEDIO D
			if(resultado.promedioD<2){
				setEsfuerzo('crisis')
			}else{
				if(resultado.promedioD<3){
					setEsfuerzo('challenge')
				}else{
					if(resultado.promedioD<3.5){
						setEsfuerzo('flow')
					}else{
						setEsfuerzo('wow')
					}
				}
			}
		}
	}, [tests, navigate])

	const calculoInitial = (datos) => {
        let totalA=0 , promedioA=0
        let totalB=0 , promedioB=0
        let totalC=0 , promedioC=0
        let totalD=0 , promedioD=0   
        let promedioTotal=0         
        for(var i=0;i<=19;i++){
            if(i<=4){
                totalA+=datos[i]
            }else{
                if(i<=9){
                    totalB+=datos[i]
                }else{
                    if(i<=14){
                        totalC+=datos[i]
                    }else{
                        totalD+=datos[i]
                    }
                }
            }
        }
        promedioA = totalA /5*2
        promedioB = totalB /5*2
        promedioC = totalC /5*2
        promedioD = totalD /5*4
        promedioTotal= (promedioA+promedioB+promedioC+promedioD)/10  
        return {
            promedioTotal: promedioTotal,
            promedioA: promedioA/2,
            promedioB: promedioB/2,
            promedioC: promedioC/2,
            promedioD: promedioD/4,
        }
    }
    
    return (
        <>
            <Container fluid className='contenido modulo2'>
                <Row>
                    <Col className='px-4 d-flex'>
                        <Link to="/client/profile" className='position-fixed icono-home'>
                            <i class="fas fa-home fa-2x"></i>
                        </Link>
                    </Col>
                </Row>
                <Row className='px-0 px-md-5'>
                    <Col xs="12" className='px-0 px-md-5'>
                        {loadingWeekly
                            ?
                                <div className='text-center'><i className='fas fa-circle-notch fa-spin fa-2x verde'></i></div>
                            :
                                <div className="form p-4">
                                    <Row className="pt-4">
                                        <Col className='text-center'>
                                            <h3>{t('weekly.title2')}</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='my-3'>
                                            <div className='align-items-center justify-content-center'>
                                                {['crisis','challenge','flow','wow'].map((estado, indice) => (
                                                        <Row key={indice}>
                                                            <Col className='col-12 col-md-8 mx-auto'>
                                                            {miEstado !== estado ?
                                                                <Card className={`mb-4 w-70 border-grande hover-${estado}`}>
                                                                    <Card.Body className='pb-2 d-flex align-items-center'>
                                                                        <Col xs='3' className='borde-derecho-verde text-center'>
                                                                            <div className={`img-fluid mx-auto bg-image-${estado}`} alt="" width="94"/>
                                                                            <h5 className={`text-center text-uppercase ${estado}-texto`}>{t(`initial.${estado}`)}</h5>
                                                                        </Col>
                                                                        <Col xs='9' className='hover-description ps-3'>
                                                                            {t(`initial.${estado}-phrase`)}
                                                                        </Col>
                                                                    </Card.Body>
                                                                </Card>
                                                            :
                                                                <Card className={`mb-4 border-grande hover-${estado}-now`}>
                                                                    <Card.Body className='pb-2 d-flex align-items-center'>
                                                                        <Col xs='3' className='borde-derecho-verde text-center'>
                                                                            <div className={`img-fluid mx-auto bg-image-${estado}-now`} alt="" width="94"/>
                                                                            <h5 className={`text-center text-uppercase ${estado}-texto`}>{t(`initial.${estado}`)}</h5>
                                                                        </Col>
                                                                        <Col xs='9' className='hover-description ps-3'>
                                                                            {t(`initial.${estado}-phrase`)}
                                                                        </Col>
                                                                    </Card.Body>
                                                                </Card>
                                                            }
                                                            </Col>
                                                        </Row>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>                            
                                    <Row>
                                        <Col xs={12} sm={6} className='text-start order-2 order-md-1 my-3'>
                                            <h3>{t('weeklyresult.title')}</h3>
                                            <p>{t('weeklyresult.description')}</p>
                                        </Col>
                                        <Col xs={12} sm={6} className='text-end order-1 order-md-2 my-3'>
                                            <Link to="/client/test/responsibility/weekly">
                                                <Button>{t('weeklyresult.toweekly')}</Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {tests
                                            ?
                                                tests[tests?.length-1]
                                                    ?
                                                        <Col className='text-start'>
                                                            {['health','success','company','effort'].map((steps,id) => (
                                                                <Fragment key={id}>
                                                                    <Card className="my-4">
                                                                        <Card.Body>
                                                                            <div id={`inital_test_${steps}`} key={steps} >
                                                                                <h3 className='titulo-paso-test d-flex align-items-center'>
                                                                                    <img
                                                                                        src={`/images/initial_test_${id+1}.png`}
                                                                                        className="d-inline-block align-top justify-center pe-3"
                                                                                        alt=""
                                                                                        width="80"
                                                                                    />
                                                                                    {t('weekly.'+steps)}
                                                                                </h3>
                                                                                {[1,2,3,4,5].map((question) => (
                                                                                    <div key={`${steps+question}`} attr_key={`${steps+question}`}>
                                                                                        <Row className='mt-4'>
                                                                                            <Col xs="12" lg="6" className='align-self-center'>
                                                                                                {t('weekly.question'+(question+(id*5)))}
                                                                                            </Col>
                                                                                            <Col  xs="12" lg="6" className='d-flex flex-column'>
                                                                                                <NeverAlways/>
                                                                                                <Row className='text-center'>
                                                                                                {[1,2,3,4].map((number) => (
                                                                                                    <Col key={number}>
                                                                                                        <Form.Check type="radio" name={`q${question+(id*5)}`} onChange={() => {}} value={number || ''} inline key={`${steps+question+number}`} checked={tests[tests.length-1]?.responses[question+(id*5)-1] === number ? true : false} disabled/>
                                                                                                    </Col>
                                                                                                ))}
                                                                                                </Row>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        {question !== 5 
                                                                                            ?
                                                                                                <hr/>
                                                                                            :
                                                                                                <></>
                                                                                        }
                                                                                    </div>
                                                                                    
                                                                                ))}
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                        {id === 0 ? 
                                                                            <Card className="mb-2">
                                                                                <Card.Body className='pb-2 text-center d-flex align-items-center'>
                                                                                    <img src={`/images/iconos-estados/`+salud+`.png`} className="img-fluid" alt="" width="64"/>
                                                                                    <h5 className={'text-center text-uppercase ps-3 '+salud+'-texto'}>{t(`weekly.`+salud)}</h5>
                                                                                </Card.Body>
                                                                            </Card> : <></>
                                                                        }
                                                                        {id === 1 ? 
                                                                            <Card className="mb-2">
                                                                                <Card.Body className='pb-2 text-center d-flex align-items-center'>
                                                                                    <img src={`/images/iconos-estados/`+exito+`.png`} className="img-fluid" alt="" width="64"/>
                                                                                    <h5 className={'text-center text-uppercase ps-3 '+exito+'-texto'}>{t(`weekly.`+exito)}</h5>
                                                                                </Card.Body>
                                                                            </Card> : <></>
                                                                        }
                                                                        {id === 2 ? 
                                                                            <Card className="mb-2">
                                                                                <Card.Body className='pb-2 text-center d-flex align-items-center'>
                                                                                    <img src={`/images/iconos-estados/`+compania+`.png`} className="img-fluid" alt="" width="64"/>
                                                                                    <h5 className={'text-center text-uppercase ps-3 '+compania+'-texto'}>{t(`weekly.`+compania)}</h5>
                                                                                </Card.Body>
                                                                            </Card> : <></>
                                                                        }
                                                                        {id === 3 ? 
                                                                            <Card className="mb-2">
                                                                                <Card.Body className='pb-2 text-center d-flex align-items-center'>
                                                                                    <img src={`/images/iconos-estados/`+esfuerzo+`.png`} className="img-fluid" alt="" width="64"/>
                                                                                    <h5 className={'text-center text-uppercase ps-3 '+esfuerzo+'-texto'}>{t(`weekly.`+esfuerzo)}</h5>
                                                                                </Card.Body>
                                                                            </Card> : <></>
                                                                        }
                                                                    </div>
                                                                </Fragment>
                                                            ))}
                                                        </Col>
                                                    :
                                                        <Col className='text-start'>
                                                            {['health','success','company','effort'].map((steps,id) => (
                                                                <Fragment key={id}>
                                                                    <Card className="my-4">
                                                                        <Card.Body>
                                                                            <div id={`inital_test_${steps}`} key={steps} >
                                                                                <h3 className='titulo-paso-test d-flex align-items-center'>
                                                                                    <img
                                                                                        src={`/images/initial_test_${id+1}.png`}
                                                                                        className="d-inline-block align-top justify-center pe-3"
                                                                                        alt=""
                                                                                        width="80"
                                                                                    />
                                                                                    {t('weekly.'+steps)}
                                                                                </h3>
                                                                                {[1,2,3,4,5].map((question) => (
                                                                                    <div key={`${steps+question}`} attr_key={`${steps+question}`}>
                                                                                        <Row className='mt-4'>
                                                                                            <Col xs="12" lg="6" className='align-self-center'>
                                                                                                {t('weekly.question'+(question+(id*5)))}
                                                                                            </Col>
                                                                                            <Col  xs="12" lg="6" className='d-flex flex-column'>
                                                                                                <NeverAlways/>
                                                                                                <Row className='text-center'>
                                                                                                {[1,2,3,4].map((number, keynumber) => (
                                                                                                    <Col key={keynumber}>
                                                                                                        <Form.Check type="radio" name={`q${question+(id*5)}`} value={`${number}`} inline key={`${steps+question+number}`} disabled/>
                                                                                                    </Col>
                                                                                                ))}
                                                                                                </Row>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        {question !== 5 
                                                                                            ?
                                                                                                <hr/>
                                                                                            :
                                                                                                <></>
                                                                                        }
                                                                                    </div>
                                                                                    
                                                                                ))}
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                        {id === 0 ? 
                                                                            <Card className="mb-2">
                                                                                <Card.Body className='pb-2 text-center d-flex align-items-center'>
                                                                                    <img src={`/images/iconos-estados/`+salud+`.png`} className="img-fluid" alt="" width="64"/>
                                                                                    <h5 className={'text-center text-uppercase ps-3 '+salud+'-texto'}>{t(`weekly.`+salud)}</h5>
                                                                                </Card.Body>
                                                                            </Card> : <></>
                                                                        }
                                                                        {id === 1 ? 
                                                                            <Card className="mb-2">
                                                                                <Card.Body className='pb-2 text-center d-flex align-items-center'>
                                                                                    <img src={`/images/iconos-estados/`+exito+`.png`} className="img-fluid" alt="" width="64"/>
                                                                                    <h5 className={'text-center text-uppercase ps-3 '+exito+'-texto'}>{t(`weekly.`+exito)}</h5>
                                                                                </Card.Body>
                                                                            </Card> : <></>
                                                                        }
                                                                        {id === 2 ? 
                                                                            <Card className="mb-2">
                                                                                <Card.Body className='pb-2 text-center d-flex align-items-center'>
                                                                                    <img src={`/images/iconos-estados/`+compania+`.png`} className="img-fluid" alt="" width="64"/>
                                                                                    <h5 className={'text-center text-uppercase ps-3 '+compania+'-texto'}>{t(`weekly.`+compania)}</h5>
                                                                                </Card.Body>
                                                                            </Card> : <></>
                                                                        }
                                                                        {id === 3 ? 
                                                                            <Card className="mb-2">
                                                                                <Card.Body className='pb-2 text-center d-flex align-items-center'>
                                                                                    <img src={`/images/iconos-estados/`+esfuerzo+`.png`} className="img-fluid" alt="" width="64"/>
                                                                                    <h5 className={'text-center text-uppercase ps-3 '+esfuerzo+'-texto'}>{t(`weekly.`+esfuerzo)}</h5>
                                                                                </Card.Body>
                                                                            </Card> : <></>
                                                                        }
                                                                    </div>
                                                                </Fragment>
                                                            ))}
                                                        </Col>
                                            :
                                                <Col className='text-start'>
                                                    {['health','success','company','effort'].map((steps,id) => (
                                                        <Fragment key={id}>
                                                            <Card className="my-4">
                                                                <Card.Body>
                                                                    <div id={`inital_test_${steps}`} key={steps} >
                                                                        <h3 className='titulo-paso-test d-flex align-items-center'>
                                                                            <img
                                                                                src={`/images/initial_test_${id+1}.png`}
                                                                                className="d-inline-block align-top justify-center pe-3"
                                                                                alt=""
                                                                                width="80"
                                                                            />
                                                                            {t('weekly.'+steps)}
                                                                        </h3>
                                                                        {[1,2,3,4,5].map((question) => (
                                                                            <div key={`${steps+question}`} attr_key={`${steps+question}`}>
                                                                                <Row className='mt-4'>
                                                                                    <Col xs="12" lg="6" className='align-self-center'>
                                                                                        {t('weekly.question'+(question+(id*5)))}
                                                                                    </Col>
                                                                                    <Col  xs="12" lg="6" className='d-flex flex-column'>
                                                                                        <NeverAlways/>
                                                                                        <Row className='text-center'>
                                                                                        {[1,2,3,4].map((number, keynumber) => (
                                                                                            <Col key={keynumber}>
                                                                                                <Form.Check type="radio" name={`q${question+(id*5)}`} value={`${number}`} inline key={`${steps+question+number}`} disabled/>
                                                                                            </Col>
                                                                                        ))}
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                                {question !== 5 
                                                                                    ?
                                                                                        <hr/>
                                                                                    :
                                                                                        <></>
                                                                                }
                                                                            </div>
                                                                            
                                                                        ))}
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                {id === 0 ? 
                                                                    <Card className="mb-2">
                                                                        <Card.Body className='pb-2 text-center d-flex align-items-center'>
                                                                            <img src={`/images/iconos-estados/`+salud+`.png`} className="img-fluid" alt="" width="64"/>
                                                                            <h5 className={'text-center text-uppercase ps-3 '+salud+'-texto'}>{t(`weekly.`+salud)}</h5>
                                                                        </Card.Body>
                                                                    </Card> : <></>
                                                                }
                                                                {id === 1 ? 
                                                                    <Card className="mb-2">
                                                                        <Card.Body className='pb-2 text-center d-flex align-items-center'>
                                                                            <img src={`/images/iconos-estados/`+exito+`.png`} className="img-fluid" alt="" width="64"/>
                                                                            <h5 className={'text-center text-uppercase ps-3 '+exito+'-texto'}>{t(`weekly.`+exito)}</h5>
                                                                        </Card.Body>
                                                                    </Card> : <></>
                                                                }
                                                                {id === 2 ? 
                                                                    <Card className="mb-2">
                                                                        <Card.Body className='pb-2 text-center d-flex align-items-center'>
                                                                            <img src={`/images/iconos-estados/`+compania+`.png`} className="img-fluid" alt="" width="64"/>
                                                                            <h5 className={'text-center text-uppercase ps-3 '+compania+'-texto'}>{t(`weekly.`+compania)}</h5>
                                                                        </Card.Body>
                                                                    </Card> : <></>
                                                                }
                                                                {id === 3 ? 
                                                                    <Card className="mb-2">
                                                                        <Card.Body className='pb-2 text-center d-flex align-items-center'>
                                                                            <img src={`/images/iconos-estados/`+esfuerzo+`.png`} className="img-fluid" alt="" width="64"/>
                                                                            <h5 className={'text-center text-uppercase ps-3 '+esfuerzo+'-texto'}>{t(`weekly.`+esfuerzo)}</h5>
                                                                        </Card.Body>
                                                                    </Card> : <></>
                                                                }
                                                            </div>
                                                        </Fragment>
                                                    ))}
                                                </Col>
                                        }
                                    </Row>
                                    <Row className="pt-4">
                                        <Col className='text-start'>
                                            <h3>{t('weekly.title3')}</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-start my-3'>
                                            <Card className="my-4">
                                                <Card.Body>
                                                    <div>
                                                        {tests
                                                            ?
                                                                [1,2,3,4,5,6,7,8].map((number, keynumber) => (
                                                                    tests[tests?.length-number]
                                                                        ?
                                                                            <Row key={keynumber} className='align-items-center px-0 px-md-5' number={number} hidden={number === 0 || number === 1 || number === 2 || number === 3 || number === 4 ? displayElements : !displayElements}>
                                                                                <Col xs={12} sm={6} className='text-center text-md-left px-0 px-md-5'>                                                                                    
                                                                                    <label className="texto-fechas">
                                                                                        { new Date(Date.parse(tests[tests?.length-number].date)).getDate()<10 ? '0'+new Date(Date.parse(tests[tests?.length-number].date)).getDate() : new Date(Date.parse(tests[tests?.length-number].date)+1).getDate()}/{ new Date(Date.parse(tests[tests?.length-number].date)+1).getMonth()<9 ? '0'+parseInt(new Date(Date.parse(tests[tests?.length-number].date)).getMonth()+1) : parseInt(new Date(Date.parse(tests[tests?.length-number].date)).getMonth()+1)}/{ new Date(Date.parse(tests[tests?.length-number].date)).getFullYear()}</label>
                                                                                </Col>
                                                                                <Col xs={12} sm={6} className='text-center text-md-right px-0 px-md-5'>
                                                                                    {tests[tests?.length-number].result === 'crisis' ? 
                                                                                        <Col className='pb-2 d-flex align-items-center justify-content-md-end justify-content-center'>
                                                                                            <div className={"img-fluid bg-image-crisis"} alt="" width="94"/>
                                                                                            <h5 className={'text-uppercase crisis-texto'}>{t('weekly.crisis')}</h5>
                                                                                        </Col>
                                                                                    : 
                                                                                        <></>
                                                                                    }
                                                                                    {tests[tests?.length-number].result === 'challenge' ? 
                                                                                        <Col className='pb-2 d-flex align-items-center justify-content-md-end justify-content-center'>
                                                                                            <div className={"img-fluid bg-image-challenge"} alt="" width="94"/>
                                                                                            <h5 className={'text-uppercase challenge-texto'}>{t('weekly.challenge')}</h5>
                                                                                        </Col>
                                                                                    :
                                                                                        <></>
                                                                                    }
                                                                                    {tests[tests?.length-number].result === 'flow' ? 
                                                                                        <Col className='pb-2 d-flex align-items-center justify-content-md-end justify-content-center'>
                                                                                            <div className={"img-fluid bg-image-flow"} alt="" width="94"/>
                                                                                            <h5 className={'text-uppercase flow-texto'}>{t('weekly.flow')}</h5>
                                                                                        </Col>
                                                                                    :
                                                                                        <></>
                                                                                    }
                                                                                    {tests[tests?.length-number].result === 'wow' ? 
                                                                                        <Col className='pb-2 d-flex align-items-center justify-content-md-end justify-content-center'>
                                                                                            <div className={"img-fluid bg-image-wow"} alt="" width="94"/>
                                                                                            <h5 className={'text-uppercase wow-texto'}>{t('weekly.wow')}</h5>
                                                                                        </Col>
                                                                                    :
                                                                                        <></>
                                                                                    }
                                                                                </Col>
                                                                                <hr/>
                                                                            </Row>
                                                                        :
                                                                            <Fragment key={keynumber}></Fragment>
                                                                ))
                                                            :
                                                                <></>
                                                        }
                                                    </div>
                                                    
                                                    <div className='text-end mx-5'>
                                                        {tests?.length >3
                                                            ?
                                                            <>
                                                                <span className='btn-vacio' hidden={displayElements} onClick={() => setDisplayElements(true)}>
                                                                    <i className='fas fa-lg fa-chevron-right'></i>
                                                                </span>
                                                                <span className='btn-vacio' hidden={!displayElements} onClick={() => setDisplayElements(false)}>
                                                                    <i className='fas fa-lg fa-chevron-left'></i>
                                                                </span>
                                                            </>
                                                            :
                                                            <></>
                                                        }   
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className='text-end'>
                        <Link to="/client/test/responsibility/evaluation">
                            <Button>{t('weeklyresult.continue')}</Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </>
    )
}