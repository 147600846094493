import React, { useEffect, useState, Fragment} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { getPersonalTest, sendPersonalPurpose } from '../../../../actions/personalTestActions'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'


export default function Personalresume() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const {personalTest} = useSelector(state=>state.personalTest)
    const [modalShow, setModalShow] = useState(false)
    const [purpose, setPurpose] = useState('')
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client?.id){
            dispatch(getPersonalTest(client.id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, dispatch, t])
    
    useEffect(() => {
        if(personalTest){
            setPurpose(personalTest.purpose)
        }
    }, [personalTest])

    const savePersonalPurpose = () => {
        dispatch(sendPersonalPurpose({
            user: client.id,
            purpose: purpose
        }))
        Swal.fire({
            icon: 'success',
            text: t('impact.purposeedited'),
            showConfirmButton: true,
            confirmButtonText: t('impact.great')
        })
    }

    return (
    <>
        <Container fluid className='contenido modulo3'>
            <Row className='px-1 px-md-5'>
                <Col xs="12" className='mx-auto'>
                    <div className="form p-md-4 p-0">
                        <Row>
                            <Col className='px-4 d-flex'>
                                <h2 className='verde'>{t('impact.title')}</h2>
                                <Link to="/client/profile" className='position-fixed icono-home'>
                                    <i class="fas fa-home fa-2x"></i>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='px-4 pb-3'>
                                <div className="alert auto alert-success text-center" role="alert">
                                    <h3 className='m-0 text-white'>{t('impact.alert2')}</h3>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='px-4 mb-3'>
                                <p className='mb-3'><strong>{t('impact.intro1')}</strong></p>
                                <p className='my-3'>{t('impact.intro2')}</p>
                                <p className='my-3'>{t('impact.intro3')}</p>
                                <p className='my-3'>{t('impact.intro4')}</p>
                                <p className='my-3'>{t('impact.intro5')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='px-4 mb-3'>
                                <p className='my-0'><strong>{t('impact.paragraph1')}</strong></p>
                            </Col>
                        </Row>
                        <Card>
                            <Card.Body className='px-2 px-md-5'>
                                <Row>
                                    <Col>
                                        <Row>
                                        {personalTest
                                            ?
                                                <Fragment>
                                                    <Row className='my-2'>
                                                        <Col>
                                                            <strong>{t('impact.textpurpose1')}</strong>
                                                        </Col>
                                                    </Row>
                                                    <Row className='my-2'>
                                                        <Col>
                                                            <strong>{t('impact.textpurpose2')}: </strong>                                                            
                                                            {personalTest?.motivate?.map((motivation, imotivation) => (
                                                                personalTest?.motivate.length !== imotivation+1
                                                                    ?
                                                                        personalTest?.motivate.length !== imotivation+2
                                                                            ?
                                                                                <span key={imotivation} className='verde'>{t('virtues.'+motivation)}, </span>
                                                                            :
                                                                                <span key={imotivation} className='verde'>{t('virtues.'+motivation)} </span>
                                                                    :
                                                                        <span key={imotivation} className='verde'>{t('impact.and')} {t('virtues.'+motivation)}.</span>
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                    <Row className='my-2'>
                                                        <Col>
                                                            <strong>{t('impact.textpurpose4')}: </strong> <span className='verde'>{personalTest?.favourite}.</span>
                                                        </Col>
                                                    </Row>
                                                    <Row className='my-2'>
                                                        <Col>
                                                            <strong>{t('impact.textpurpose5')}: </strong> <span className='verde'>{personalTest?.lifeproject}.</span>
                                                        </Col>
                                                    </Row>
                                                    <Row className='my-2'>
                                                        <Col>
                                                            <strong>{t('impact.textpurpose6')}: </strong> <span className='verde'>{t('impact.'+personalTest?.duration)}. {t('impact.'+personalTest?.dimension)}.</span>
                                                        </Col>
                                                    </Row>
                                                </Fragment>
                                            :
                                                <Fragment></Fragment>
                                        }
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                         </Card>
                        <Row className='my-3 text-end'>
                            <Col>
                               
                            </Col>
                        </Row>
                        <Card>
                            <Card.Body className='px-2 px-md-5'>
                                <Row>
                                    <Col>
                                        <strong className="verde mb-2">{t('impact.alert2')}</strong>
                                        <Form.Group>
                                            <Form.Control
                                                    as="textarea"
                                                    className="form-control form-control-verde"
                                                    name='purpose'
                                                    rows='6'
                                                    value={purpose}
                                                    onChange={(e) => setPurpose(e.target.value)}
                                            />  
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                         </Card>
                        <Row className='my-3'>
                            <Col className='text-end'>
                                <Link to="/client/test/impact/personal">
                                    <Button variant='primary' type='buttom' className='me-3'>
                                        {t('impact.editmypurpose')}
                                    </Button>
                                </Link>
                                <Button variant='primary' type='buttom' className='me-3' onClick={() => setModalShow(true)}>
                                    {t('impact.examples')}
                                </Button>

                                <Button variant='primary' type='buttom' onClick={savePersonalPurpose}>
                                    {t('global.save')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className='my-4 botones-pasos'>
                <Col className="d-flex justify-content-md-space-around justify-content-center flex-wrap">
                    
                    <Button className='boton-blanco' variant='primary' type="submit">
                        {t('impact.personal')}
                    </Button>
            
                    <Link to="/client/test/impact/professional">
                        <Button className='boton-gris' variant='primary' type="submit">
                            {t('impact.professional')}
                        </Button>
                    </Link>
            
                    <Link to="/client/test/impact/couple">
                        <Button className='boton-gris' variant='primary' type="submit">
                            {t('impact.couple')}
                        </Button>
                    </Link>
                            
                </Col>
            </Row>            
            <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('impact.examplespuposes')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {t('impact.examplefit1')}
                        </Col>
                    </Row>
                    <Row>
                        <Col><hr></hr></Col>
                    </Row>
                    <Row>
                        <Col>
                            {t('impact.examplefit2')}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>{t('prospect.close')}</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    </>
    )
}