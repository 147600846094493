import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Container, Row, Col, Form, Button, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getGuide, editGuide, sendGuide, deleteComment, aprovecomment } from '../../actions/guideActions'
import { Link, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from 'react'

export default function Explain() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {virtue} = useParams()
    const {guide} = useSelector(state=>state.guide)

    const [url, setUrl] = useState('')
    const [text1, setText1] = useState('')
    const [text2, setText2] = useState('')
    const [text3, setText3] = useState('')
    const [text4, setText4] = useState('')

    const text1Ref = useRef(null);
    const text2Ref = useRef(null);
    const text3Ref = useRef(null);
    const text4Ref = useRef(null);

    useEffect(() => {
        if(guide){
            setUrl(guide.url)
            setText1(guide.text1)
            setText2(guide.text2)
            setText3(guide.text3)
            setText4(guide.text4)
        }
    }, [guide])

    useEffect(() => {
        dispatch(getGuide(virtue))
    }, [virtue, dispatch])

    const updateGuide = async(e) =>{
        e.preventDefault()

        let url = document.getElementById('url').value

        if(guide){
            dispatch(editGuide(guide._id, url, text1, text2, text3, text4))
        }else{
            dispatch(sendGuide(virtue, url, text1, text2, text3, text4))
        }

        Swal.fire({
            showConfirmButton: true,
            confirmButtonText: t('guide.great'),
            icon: 'success',
            text: t('guide.updatesuccess')
        })
    }

    const removeComment = async(e, comment) =>{
        e.preventDefault()

        Swal.fire({
            title: t('admin.areusure'),
            showDenyButton: true,
            confirmButtonText: t('guide.great'),
            denyButtonText: t('guide.cancel'),
            text: t('guide.commentdelete')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteComment(guide._id, comment))
            }
        })
    }

    const acceptComment = async(e, comment) =>{
        e.preventDefault()

        Swal.fire({
            title: t('guide.aprovecomment'),
            showDenyButton: true,
            confirmButtonText: t('guide.yes'),
            denyButtonText: t('guide.no'),
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(aprovecomment(guide._id, comment))
            }
        })
    }

    const deniedComment = async(e, comment) =>{
        e.preventDefault()

        Swal.fire({
            title: t('guide.areusuredenied'),
            showDenyButton: true,
            confirmButtonText: t('guide.yes'),
            denyButtonText: t('guide.no')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteComment(guide._id, comment))
            }
        })
    }

    return (
        <>
            <Container fluid className='contenido test-responsibility'>
                <Row className='px-0 px-md-5'>
                    <Col xs="12" className='mx-auto'>
                        <div className="form p-2 p-md-4">
                            <div>
                                <Row>
                                    <Col className='px-4 d-flex'>
                                        <h2 className='verde'>{t('guide.guides')}</h2>
                                        <Link to="/admin/guides" className='position-fixed icono-home'>
                                            <i class="fas fa-home fa-2x"></i>
                                        </Link>
                                    </Col>
                                </Row>
  
                            </div>
                            <Card className='bg-graylight'>
                                <Card.Body className='px-5'>
                                    <div className='pt-5 pb-4'>
                                        {guide?.virtue
                                            ?
                                                <div className="alert auto alert-success text-center me-auto" role="alert">
                                                    <h3 className='m-0 text-white'>{t('virtues.'+guide.virtue)}</h3>
                                                </div>
                                            :
                                                <></>
                                        }
                                        {guide?.url
                                            ?
                                                <div className='contenedor-video'>
                                                    <div id='video'>
                                                        <iframe src={`https://www.youtube.com/embed/${url}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                                    </div>
                                                    <p className='my-4'>{t('guide.urlnow')}: {url}</p>
                                                    <Form.Control
                                                        type="text"
                                                        className='form-control'
                                                        id='url'
                                                        placeholder={t('guide.url')}
                                                    />
                                                </div>
                                            :
                                                <>
                                                    <p className='my-4'>{t('guide.urlnow')}</p>
                                                    <Form.Control
                                                        type="text"
                                                        className='form-control mb-4'
                                                        id='url'
                                                    />
                                                </>
                                        }
                                        <h4 className='mt-4'>{t('guide.spanish')}</h4>
                                        {guide?.text1
                                            ?
                                                <>
                                                    <p className='texto-verde mt-4'><strong>{t('guide.text1')}</strong></p>
                                                    <p dangerouslySetInnerHTML={{__html: text1}}></p>
                                                    <Editor
                                                        apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
                                                        onInit={(evt, editor) => text1Ref.current = editor}
                                                        onChange={() => setText1(text1Ref?.current?.getContent())}
                                                        initialValue={text1}
                                                        init={{
                                                        height: 300,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                        ],
                                                        toolbar: 'undo redo | blocks | ' +
                                                            'bold italic forecolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                            'removeformat | help',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                        }}
                                                    />
                                                </>
                                            :
                                                <>
                                                    <p className='texto-verde mt-4'><strong>{t('guide.text1')}</strong></p>
                                                    <Editor
                                                        apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
                                                        onInit={(evt, editor) => text1Ref.current = editor}
                                                        onChange={() => setText1(text1Ref?.current?.getContent())}
                                                        initialValue={text1}
                                                        init={{
                                                        height: 300,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                        ],
                                                        toolbar: 'undo redo | blocks | ' +
                                                            'bold italic forecolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                            'removeformat | help',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                        }}
                                                    />
                                                </>
                                        }
                                        {guide?.text2
                                            ?
                                                <>
                                                    <p className='texto-verde mt-4'><strong>{t('guide.text2')}</strong></p>
                                                    <p dangerouslySetInnerHTML={{__html: text2}}></p>
                                                    <Editor
                                                        apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
                                                        onInit={(evt, editor) => text2Ref.current = editor}
                                                        onChange={() => setText2(text2Ref?.current?.getContent())}
                                                        initialValue={text2}
                                                        init={{
                                                        height: 300,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                        ],
                                                        toolbar: 'undo redo | blocks | ' +
                                                            'bold italic forecolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                            'removeformat | help',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                        }}
                                                    />
                                                </>
                                            :
                                                <>
                                                    <p className='texto-verde mt-4'><strong>{t('guide.text2')}</strong></p>
                                                    <Editor
                                                        apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
                                                        onInit={(evt, editor) => text2Ref.current = editor}
                                                        onChange={() => setText2(text2Ref?.current?.getContent())}
                                                        initialValue={text2}
                                                        init={{
                                                        height: 300,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                        ],
                                                        toolbar: 'undo redo | blocks | ' +
                                                            'bold italic forecolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                            'removeformat | help',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                        }}
                                                    />
                                                </>
                                        }
                                        <h4 className='mt-4'>{t('guide.english')}</h4>
                                        {guide?.text3
                                            ?
                                                <>
                                                    <p className='texto-verde mt-4'><strong>{t('guide.text1')}</strong></p>
                                                    <p dangerouslySetInnerHTML={{__html: text3}}></p>
                                                    <Editor
                                                        apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
                                                        onInit={(evt, editor) => text3Ref.current = editor}
                                                        onChange={() => setText3(text3Ref?.current?.getContent())}
                                                        initialValue={text3}
                                                        init={{
                                                        height: 300,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                        ],
                                                        toolbar: 'undo redo | blocks | ' +
                                                            'bold italic forecolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                            'removeformat | help',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                        }}
                                                    />
                                                </>
                                            :
                                                <>
                                                    <p className='texto-verde mt-4'><strong>{t('guide.text1')}</strong></p>
                                                    <Editor
                                                        apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
                                                        onInit={(evt, editor) => text3Ref.current = editor}
                                                        onChange={() => setText3(text3Ref?.current?.getContent())}
                                                        initialValue={text3}
                                                        init={{
                                                        height: 300,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                        ],
                                                        toolbar: 'undo redo | blocks | ' +
                                                            'bold italic forecolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                            'removeformat | help',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                        }}
                                                    />
                                                </>
                                        }
                                        {guide?.text4
                                            ?
                                                <>
                                                    <p className='texto-verde mt-4'><strong>{t('guide.text2')}</strong></p>
                                                    <p dangerouslySetInnerHTML={{__html: text4}}></p>
                                                    <Editor
                                                        apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
                                                        onInit={(evt, editor) => text4Ref.current = editor}
                                                        onChange={() => setText4(text4Ref?.current?.getContent())}
                                                        initialValue={text4}
                                                        init={{
                                                        height: 300,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                        ],
                                                        toolbar: 'undo redo | blocks | ' +
                                                            'bold italic forecolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                            'removeformat | help',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                        }}
                                                    />
                                                </>
                                            :
                                                <>
                                                    <p className='texto-verde mt-4'><strong>{t('guide.text2')}</strong></p>
                                                    <Editor
                                                        apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
                                                        onInit={(evt, editor) => text4Ref.current = editor}
                                                        onChange={() => setText4(text4Ref?.current?.getContent())}
                                                        initialValue={text4}
                                                        init={{
                                                        height: 300,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                        ],
                                                        toolbar: 'undo redo | blocks | ' +
                                                            'bold italic forecolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                            'removeformat | help',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                        }}
                                                    />
                                                </>
                                        }
                                        <Button className="mt-3" type="submit" onClick={(e) => updateGuide(e)}>{t('guide.updateguide')}</Button>
                                        <div className='pt-5 pb-4'>
                                            <div className='py-2'>
                                                {t('guide.comments')}
                                            </div>
                                            <hr/>
                                            <div className='py-2'>
                                                {guide?.comments?.length > 0
                                                    ?
                                                        <>
                                                            {guide?.comments.map((comment, icomments) => (
                                                                comment.aproved === true
                                                                    ?
                                                                        <Fragment key={icomments}>
                                                                            <p className='texto-verde align-items-center'>
                                                                                <span className="me-3" type="submit" onClick={(e) => removeComment(e, comment._id)}><i className="fa fa-trash" aria-hidden="true"></i></span>
                                                                                {comment.user.username} - { new Date(comment.date).getHours()}:{ new Date(comment.date).getMinutes()<10 ? '0'+new Date(comment.date).getMinutes() : new Date(comment.date).getMinutes()}:{ new Date(comment.date).getSeconds()<10 ? '0'+new Date(comment.date).getSeconds() : new Date(comment.date).getSeconds()}
                                                                                &nbsp;&nbsp;{ new Date(comment.date).getDate()<10 ? '0'+new Date(comment.date).getDate() : new Date(comment.date).getDate()}/{ new Date(comment.date).getMonth()+1<9 ? '0'+parseInt(new Date(comment.date).getMonth()+1) : parseInt(new Date(comment.date).getMonth()+1)}/{ new Date(comment.date).getFullYear()}
                                                                            </p>
                                                                            <p>{comment.comment}</p>
                                                                            <hr/>
                                                                        </Fragment>
                                                                    :
                                                                        <Fragment key={icomments}></Fragment>
                                                            ))}
                                                            {guide?.comments.map((comment, icomments) => (
                                                                comment.aproved === false
                                                                    ?
                                                                        <Fragment key={icomments}>
                                                                            <p className='texto-verde align-items-center'>
                                                                                <span className="me-3" type="submit" onClick={(e) => acceptComment(e, comment._id)}><i className="fa fa-check-circle" aria-hidden="true"></i></span>
                                                                                <span className="me-3" type="submit" onClick={(e) => deniedComment(e, comment._id)}><i className="fa fa-times-circle" aria-hidden="true"></i></span>
                                                                                {comment.user.username} - { new Date(comment.date).getHours()}:{ new Date(comment.date).getMinutes()<10 ? '0'+new Date(comment.date).getMinutes() : new Date(comment.date).getMinutes()}:{ new Date(comment.date).getSeconds()<10 ? '0'+new Date(comment.date).getSeconds() : new Date(comment.date).getSeconds()}
                                                                                &nbsp;&nbsp;{ new Date(comment.date).getDate()<10 ? '0'+new Date(comment.date).getDate() : new Date(comment.date).getDate()}/{ new Date(comment.date).getMonth()+1<9 ? '0'+parseInt(new Date(comment.date).getMonth()+1) : parseInt(new Date(comment.date).getMonth()+1)}/{ new Date(comment.date).getFullYear()}
                                                                            </p>
                                                                            <p>{comment.comment}</p>
                                                                            <hr/>
                                                                        </Fragment>
                                                                    :
                                                                        <Fragment key={icomments}></Fragment>
                                                            ))}
                                                        </>
                                                    :
                                                        <>{t('guide.thereisntcomments')}</>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}