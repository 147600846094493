import { Document, Page, Text, View, Image, StyleSheet} from "@react-pdf/renderer"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"


export default function ResumeReport(info){
    const { t } = useTranslation()
    
    
    
	let styles = StyleSheet.create({
		pagina: {			
			paddingTop: 20,
			paddingRight: 30,
			paddingLeft: 30,
			paddingBottom: 20,
			fontSize: 18,
			fontFamily: 'Helvetica'
		},
		cabeceraPagina:{
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			marginBottom: 30 
		},	
		image:{
			flex:1,	
			maxWidth: 150
		},
		titulo: {
			flex: 1,
			fontSize: 16,
			fontFamily: 'Helvetica-Bold',
			color: "#24B8B0",
			marginBottom: 20,
            textAlign: 'right'
		},
		titulo2: {
			borderWidth: 1,
			borderColor: "#24B8B0",
			borderRadius: 40,
			color: "#24B8B0",
			fontSize: 16,
			fontFamily: 'Helvetica-Bold',
			marginBottom: 20,	
			paddingVertical: 10,
			paddingHorizontal: 20,
			width: 250
		},
        contenedorVirtudes: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 20
        },
        texto : {
            fontSize: 12,
			fontFamily: 'Helvetica',
			color: "#24B8B0"			
        },
		caja: {
			borderWidth: 1,
			borderColor: "#24B8B0",
			borderRadius: 20,
            flex: 1,
            marginHorizontal: 5,
			paddingHorizontal: 20,
			paddingVertical:20,
		},
        cabeceraVirtud: {
			fontSize: 14,
			fontFamily: 'Helvetica-Bold',		
			color: "#24B8B0",
			marginBottom: 10,
            marginHorizontal: 5,
            textAlign: 'center',
            textTransform: 'uppercase',
		},
		virtud: {
			fontSize: 12,
			fontFamily: 'Helvetica-Bold',		
			color: "#24B8B0",
			marginBottom: 5,
            marginHorizontal: 5,
            textAlign: 'center',  
            textTransform: 'capitalize'          
		},
		cabecera:{
			display: 'flex',
			flexDirection: 'row',
			marginVertical: 10
		},		
		head: {
			color: "#24B8B0",
			flex: 1,
			fontSize: 12,
			fontFamily: 'Helvetica-Bold',
		},
		separador: {							
			borderWidth: 1,
			borderColor: "#24B8B0"
		},
		bloqueVirtud: {
			display: 'flex',
			flexDirection: 'row',			
			paddingVertical: 5,
			alignItems: 'center'
		},
		datoTitulo: {
			color: "#24B8B0",
			flex: 1,
			fontFamily: 'Helvetica-Bold',
			fontSize: 10,
			paddingRight: 10,
			paddingVertical: 5,	
		},		
		dato: {
			color: "#24B8B0",
			flex: 1,
			fontFamily: 'Helvetica',
			fontSize: 10,
			paddingRight: 10,
			paddingVertical: 5,	
		},		
		noexercises: {
			color: "#24B8B0",
			flex: 1,
			fontSize: 12,
			fontFamily: 'Helvetica-Bold',
			marginBottom: 10
		},
		pageNumber: {
			position: 'absolute',
			fontSize: 10,
			bottom: 15,
			left: 0,
			right: 0,
			textAlign: 'center',
			color: 'grey',
		  },
	})
	
    let datos = []
    if(info.virtuesStrongPolarity?.length > 0){
        info.virtuesStrongPolarity?.map((virtue) => (		    
				datos.push(virtue.virtue)
		))
        
    }	

	return (
		<Document>
            <Page size="A4" style={styles.pagina}>
				<View>
					<View style={styles.cabeceraPagina} fixed>
						<Image style={styles.image} src="/images/logotipo-fondo-claro.png"/>
						<View style={styles.titulo}>	
							<Text>{t('profile.profile')}</Text>
						</View>
					</View>
                    <View style={styles.titulo2}>
						<Text>{t('resume.virtues-perfil')}</Text>
					</View>					
                    <View style={styles.contenedorVirtudes}>
                        <View style={styles.caja}>
                            <Text style={styles.cabeceraVirtud}>{t('resume.hard')}</Text>
                            {info.virtuesStrong?.map((virtues, index) => (
                               <Text key={index} style={styles.virtud}>{t('virtues.'+virtues.virtue)}</Text>
							))}
                        </View>
                        <View style={styles.caja}>
                            <Text style={styles.cabeceraVirtud}>{t('resume.train')}</Text>
                            {info.virtuesWeak?.map((virtues, index) => (
                               <Text key={index} style={styles.virtud}>{t('virtues.'+virtues.virtue)}</Text>
							))}
                        </View>
                        <View style={styles.caja}>
                            <Text style={styles.cabeceraVirtud}>{t('resume.medium')}</Text>
                            {info.virtuesMedium?.map((virtues, index) => (
                               <Text key={index} style={styles.virtud}>{t('virtues.'+virtues.virtue)}</Text>
							))}
                        </View>
                    </View>
					<View wrap={false}>
						<View style={styles.titulo2}>
							<Text>{t('resume.mypolarity')}</Text>
						</View>					
						<View style={styles.contenedorVirtudes}>
							<View style={styles.caja}>
								<View>
									<Text style={styles.texto}>{t('polarity.paragraph')}</Text>
								</View>
								<View style={styles.cabecera}>
									<Text style={styles.head}>{t('polarity.apply-a-lot')}</Text>
									<Text style={styles.head}>{t('polarity.not-apply-a-lot')}</Text>
									<Text style={styles.head}>{t('polarity.result')}</Text>
								</View>
								<View >
									{datos?.map((virtue, id, virtuesStrong)=> {
										let virtueCount = 0
										return(
											info.virtuesWeaksOfStrong[virtue].length >0
											?
												<View key={id}>
												{info.virtuesWeaksOfStrong[virtue]?.map((weakVirtue, key)=>(
													info.virtuesWeakPolarity.includes(weakVirtue.name)
													?
														<View key={key} style={styles.bloqueVirtud}>
															{virtueCount++ < 1
															?													
																<Text style={styles.datoTitulo}>{t('virtues.'+virtue)}</Text>
															:
																<Text style={styles.dato}></Text>
															}
															<Text style={styles.dato}>{t('virtues.'+weakVirtue.name)}</Text>
															<Text style={styles.dato}>{t('virtues.tips.'+weakVirtue.value)}</Text>
														</View>
													:
														<Fragment key={key}></Fragment>
												))}
													{virtueCount > 0 && id + 1 !== virtuesStrong.length 
														? 
														<Text style={styles.separador}></Text> 
														: 
														<></>
													}
												</View>
											:
												<></>
										)
									})}                            
								</View>
							</View>                        
						</View>
					</View>
				</View>
				<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        			`${pageNumber} / ${totalPages}`
      			)} fixed />
            </Page>
		</Document>
	)
}