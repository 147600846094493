import React,{useEffect, useState} from 'react'
import {Container,Row,Col,Button} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
// import useAuth from '../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers } from '../../actions/usersActions'
import { getAllInitialTest } from '../../actions/initialTestActions'
import { CustomDataTable } from "../../components/design/Datatables/CustomDataTable";


export default function Users() { 
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true)

    const {users,loading} = useSelector(state=>state.users)
    const {tests} = useSelector(state=>state.initialTest)

    useEffect(()=>{
        dispatch(getUsers())
        dispatch(getAllInitialTest())
    }, [dispatch])

    useEffect(()=>{
        setIsLoading(loading)
    }, [loading])

    // Datatables
    const columns = [
        {
            name: t('users.profile'),
            cell: user =>
                <div className="d-flex align-items-center">
                    <div >
                        <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                        <div className="avatar d-inline-block align-top justify-center float-left">
                            { user?.image?.length>0
                            ?
                            <img src={user.image} alt=''/>
                            :
                            <img src="/images/avatar.png" alt=''/>
                            }
                        </div>
                        </span>
                    </div>
                </div>,
            selector: (user) => user.name,
            sortable: true,
            width:'10%'
        },{
            name: t('users.firstname'),
            cell: user =>
                <div className="d-flex align-items-center">
                    <div >
                        <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                            {user.firstname} {user.lastname}
                        </span>
                    </div>
                </div>,
            selector: (user) => user.name,
            sortable: true
            
        },{
            name: t('users.username'),
            cell: user =>
                <div className="d-flex align-items-center">
                    <div >
                        <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                            {user.username}
                        </span>
                    </div>
                </div>,
            selector: (user) => user.username,
            sortable: true,
            width:'15%'
        },{
            name: t('users.fase'),
            cell: user =>
                <div className="d-flex align-items-center">
                    <div >
                        <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                            {user.active_test ? "Fase 1" : "Fase 2"}
                        </span>
                    </div>
                </div>,
            selector: (user) => user.active_test,
            sortable: true,
            width:'15%'
        },{
            name: t('users.well-being'),
            cell: user =>
                <div className="d-flex align-items-center">
                    <div >
                        <span className='text-dark fw-bolder text-hover-primary d-block mb-1'>
                            {tests?.find(tests => tests.user === user._id)?.result !== undefined
                                ?
                                    <img
                                        src={`/images/iconos-estados/`+tests?.find(tests => tests.user === user._id)?.result+`.png`}
                                        className="img-fluid"
                                        alt=""
                                        width="94"
                                    />
                                    
                                :
                                <>{t('profile.thereisnt')}</>
                            }
                        </span>
                    </div>
                </div>,
            selector: (user) => user.username,
            sortable: true,
            width:'15%'
        },{
            name: t('users.lastlogin'),
            cell: user =>
                <div className="text-center fw-bolder mb-1 fs-6">                    
                        {user?.last_login
                            ?
                            <>
                                { new Date(Date.parse(user.last_login)).getHours()}:{ new Date(Date.parse(user.last_login)).getMinutes()<10 ? '0'+new Date(Date.parse(user.last_login)).getMinutes() : new Date(Date.parse(user.last_login)).getMinutes()}:{ new Date(Date.parse(user.last_login)).getSeconds()<10 ? '0'+new Date(Date.parse(user.last_login)).getSeconds() : new Date(Date.parse(user.last_login)).getSeconds()}&nbsp;&nbsp;&nbsp;
                                { new Date(Date.parse(user.last_login)).getDate()<10 ? '0'+new Date(Date.parse(user.last_login)).getDate() : new Date(Date.parse(user.last_login)).getDate()}/{ new Date(Date.parse(user.last_login)).getMonth()+1<9 ? '0'+parseInt(new Date(Date.parse(user.last_login)).getMonth()+1) : parseInt(new Date(Date.parse(user.last_login)).getMonth()+1)}/{ new Date(Date.parse(user.last_login)).getFullYear()}
                            </>
                            :
                            <></>
                        }
                    
                </div>,
            selector: (user) => user.username,
            sortable: true
        },{
            name: t('global.actions'),
            cell: user =>
            <>
                <Button className='d-flex p-2 m-2 align-items-center' variant="outline-success" href={`/admin/bloqueuno/`+user._id}>
                    <span className='fw-bold me-2'>1</span><i className="fas fa-chalkboard-teacher"></i>
                </Button>
                <Button className='d-flex p-2 m-2 align-items-center' variant="outline-success" href={`/admin/bloquedos/`+user._id}>
                    <span className='fw-bold me-2'>2</span><i className="fas fa-chalkboard-teacher"></i>
                </Button>
                <Button className='d-flex p-2 m-2 align-items-center' variant="outline-success" href={`/admin/bloquetres/`+user._id}>
                    <span className='fw-bold me-2'>3</span><i className="fas fa-chalkboard-teacher"></i>
                </Button>
                <Button className='p-2 mx-2' variant="outline-success" href={`/admin/user/`+user._id}>
                    <i className="fas fa-cog"></i>
                </Button>
            </>
        }
    ]
    
    return (
        <>
        {isLoading
        ?      
          <div className='d-flex justify-content-center vh-100'>            
              <span className="spinner-border spinner-border-sm" style={{width: "5rem",height: "5rem"}}></span>
          </div>
        : 
        <>        
            <Container className='cuerpo'>
                <Row>
                    <Col className='px-5'>
                        <Button className="float-end" variant="outline-success" href={`/admin/user`}>
                            <i className='fas fa-plus'></i> {t('users.new')}
                        </Button>
                        <h1 className='mb-5'>{t('users.userlist')}</h1>
                        {users
                            ?
                            <CustomDataTable columns={columns} data={users} />
                            :
                            <></>
                        }
                    </Col>
                </Row>
            </Container>
        </>
        }
        </>
    )
}