import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Container, Row, Col, Form, Button, Card, OverlayTrigger, Popover} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getUservirtue } from '../../../../actions/uservirtueActions'
import { getAllEvaluationTest } from '../../../../actions/evaluationTestActions'
import { Fragment } from 'react'
import { editTrainingTest, getAllTrainingTest, setdoneTrainingTest } from '../../../../actions/trainingTestActions'
import 'add-to-calendar-button'

export default function Essential() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const {uservirtue} = useSelector(state=>state.uservirtue)
    const {tests} = useSelector(state=>state.evaluationTest)
    const {exercises} = useSelector(state=>state.trainingTest)

    const [weakVirtues, setWeakVirtues] = useState([])
    const [mediumVirtues, setMediumVirtues] = useState([])
    const [hardVirtues, setHardVirtues] = useState([])
    
    const [weakShow,setWeakShow] = useState(false)
    const [mediumShow,setMediumShow] = useState(false)
    const [hardShow,setHardShow] = useState(false)
    const [allVirtuesWeak, setAllVirtuesWeak] = useState([[], [], [], []])
    const [allVirtuesMedium, setAllVirtuesMedium] = useState([[], [], [], []])
    const [allVirtuesHard, setAllVirtuesHard] = useState([[], [], [], []])
    const [allVirtuesExercises, setAllVirtuesExercises] = useState({
        'aceptacion': [], 'admiracion': [], 'alegria': [], 'compania': [], 'compasion': [], 'dignidad': [], 'disciplina': [],
        'educacion': [], 'generosidad': [], 'guia': [], 'humildad': [], 'identidad': [], 'inspiracion': [], 'justicia': [],
        'lealtad': [], 'libertad': [], 'paciencia': [], 'perdon': [], 'perseverancia': [], 'proteccion': [], 'responsabilidad': [],
        'sabiduria': [], 'valentia': [],
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client){
            dispatch(getUservirtue(client.id))
            dispatch(getAllEvaluationTest(client.id))
            dispatch(getAllTrainingTest(client.id))
        }
    }, [client, dispatch])

    useEffect(() => {
        if(uservirtue){
            let weakVirtuesArray = []
            let mediumVirtuesArray = []
            let hardVirtuesArray = []

            for (const virtue in uservirtue) {
                if(virtue !== '_id' && virtue !== '__v' && virtue !== 'user'){
                    if(uservirtue[virtue] === 'debil'){
                        weakVirtuesArray.push(virtue)
                    }
                    if(uservirtue[virtue] === 'media'){
                        mediumVirtuesArray.push(virtue)
                    }
                    if(uservirtue[virtue] === 'fuerte'){
                        hardVirtuesArray.push(virtue)
                    }
                }
            }

            setWeakVirtues(weakVirtuesArray)
            setMediumVirtues(mediumVirtuesArray)
            setHardVirtues(hardVirtuesArray)
            weakVirtuesArray.map((virtue) => {
                if(allVirtuesExercises[virtue].length > 0){
                    setWeakShow(true)
                }
                return true
            })
            mediumVirtuesArray.map((virtue) => {
                if(allVirtuesExercises[virtue].length > 0){
                    setMediumShow(true)
                }
                return true
            })
            hardVirtuesArray.map((virtue) => {
                if(allVirtuesExercises[virtue].length > 0){
                    setHardShow(true)
                }
                return true
            })
        }
    }, [uservirtue,allVirtuesExercises])

    useEffect(() => {
        if(exercises){
            saveExercises()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exercises])

    const saveExercises = async() => {
        let allVirtuesExercisesCopy = {
            'aceptacion': [],
            'admiracion': [],
            'alegria': [],
            'compania': [],
            'compasion': [],
            'dignidad': [],
            'disciplina': [],
            'educacion': [],
            'generosidad': [],
            'guia': [],
            'humildad': [],
            'identidad': [],
            'inspiracion': [],
            'justicia': [],
            'lealtad': [],
            'libertad': [],
            'paciencia': [],
            'perdon': [],
            'perseverancia': [],
            'proteccion': [],
            'responsabilidad': [],
            'sabiduria': [],
            'valentia': [],
        }

        let dateMax = null
        Promise.all(await exercises?.map(async(exercise) => {
            if(dateMax === null || dateMax < new Date(exercise.date)){
                dateMax = new Date(exercise.date).getTime()
            }
            if(exercise.done === true && exercise.favourite === true){
                if(allVirtuesExercisesCopy[exercise.virtue]){
                    if(!allVirtuesExercisesCopy[exercise.virtue]?.find(exercisesFind => exercisesFind._id === exercise._id)){
                        allVirtuesExercisesCopy = {...allVirtuesExercisesCopy, [exercise.virtue]: [...allVirtuesExercisesCopy[exercise.virtue], exercise]}
                    }
                }else{
                    allVirtuesExercisesCopy = {...allVirtuesExercisesCopy, [exercise.virtue]: [exercise]}
                }
            }

            return true
        })).then(
            setAllVirtuesExercises(allVirtuesExercisesCopy)
        )
    }

    useEffect(() => {
        if(tests){
            let arrayweakVirtuesCrisis = []
            let arrayweakVirtuesChallenge = []
            let arrayweakVirtuesFlow = []
            let arrayweakVirtuesWow = []

            let arraymediumVirtuesCrisis = []
            let arraymediumVirtuesChallenge = []
            let arraymediumVirtuesFlow = []
            let arraymediumVirtuesWow = []

            let arrayhardVirtuesCrisis = []
            let arrayhardVirtuesChallenge = []
            let arrayhardVirtuesFlow = []
            let arrayhardVirtuesWow = []
            
            tests?.map((test, itest) => {
                if(itest === 1){                    
                    weakVirtues?.map((virtue) => {
                        if(test[virtue] === 'diario'){
                            arrayweakVirtuesCrisis.push(virtue)
                        }
                        if(test[virtue] === 'algunos'){
                            arrayweakVirtuesChallenge.push(virtue)
                        }
                        if(test[virtue] === 'pocos'){
                            arrayweakVirtuesFlow.push(virtue)
                        }
                        if(test[virtue] === 'ninguno'){
                            arrayweakVirtuesWow.push(virtue)
                        }
    
                        return true
                    })
    
                    mediumVirtues?.map((virtue) => {
                        if(test[virtue] === 'diario'){
                            arraymediumVirtuesCrisis.push(virtue)
                        }
                        if(test[virtue] === 'algunos'){
                            arraymediumVirtuesChallenge.push(virtue)
                        }
                        if(test[virtue] === 'pocos'){
                            arraymediumVirtuesFlow.push(virtue)
                        }
                        if(test[virtue] === 'ninguno'){
                            arraymediumVirtuesWow.push(virtue)
                        }
    
                        return true
                    })
    
                    hardVirtues?.map((virtue) => {
                        if(test[virtue] === 'diario'){
                            arrayhardVirtuesCrisis.push(virtue)
                        }
                        if(test[virtue] === 'algunos'){
                            arrayhardVirtuesChallenge.push(virtue)
                        }
                        if(test[virtue] === 'pocos'){
                            arrayhardVirtuesFlow.push(virtue)
                        }
                        if(test[virtue] === 'ninguno'){
                            arrayhardVirtuesWow.push(virtue)
                        }
    
                        return true
                    })
            
                }

                return true
            })
            setAllVirtuesWeak([
                arrayweakVirtuesCrisis,
                arrayweakVirtuesChallenge,
                arrayweakVirtuesFlow,
                arrayweakVirtuesWow,
            ])
            
            setAllVirtuesMedium([
                arraymediumVirtuesCrisis,
                arraymediumVirtuesChallenge,
                arraymediumVirtuesFlow,
                arraymediumVirtuesWow,
            ])
            setAllVirtuesHard([
                arrayhardVirtuesCrisis,
                arrayhardVirtuesChallenge,
                arrayhardVirtuesFlow,
                arrayhardVirtuesWow
            ])

            
            
        }
    }, [tests, weakVirtues, mediumVirtues, hardVirtues])

    const changeExercise = (e, exercise) =>{
        e.preventDefault()

        let level = document.getElementById('level-'+exercise).value
        let frequency = document.getElementById('frequency-'+exercise).value

        dispatch(editTrainingTest(exercise, level, frequency))
    }

    const deleteExercise = (e, exercise) =>{
        e.preventDefault()

        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('essential.letsgo'),
            denyButtonText: t('essential.cancel'),
            icon: 'warning',
            text: t('essential.deleteessential')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setdoneTrainingTest(exercise, false))
                Swal.fire(t('essential.deletessuccess'), '', 'success')
            }
        })
    }

    return (
        <>
            <Container fluid className='contenido modulo2'>
                <Row className='px-0 px-md-5'>
                    <Col xs="12" className='mx-auto'>
                        <div className="form p-2 p-md-4">
                            <div>
                                <Row>
                                    <Col className='px-4 d-flex'>
                                        <h2 className='verde'>{t('essential.title')}</h2>
                                        <Link to="/client/profile" className='position-fixed icono-home'>
                                            <i class="fas fa-home fa-2x"></i>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='px-4 pb-3'>
                                        <div className="alert auto alert-success text-center" role="alert">
                                            <h3 className='m-0 text-white'>{t('essential.alert')}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='px-4'>
                                        <p><strong>{t('essential.paragraph1')}</strong></p>
                                    </Col>
                                </Row>
                            </div>
                            {[allVirtuesWeak,allVirtuesMedium,allVirtuesHard].map((bloque,key) => (  
                                (weakShow && key === 0) || (mediumShow && key === 1) || (hardShow && key === 2)
                                ?
                                 <Card className='my-5 pt-4 pb-2 px-2 px-md-5' key={key}>
                                    <Card.Body>

                                        {bloque?.map((typevirtue, itypevirtue) => (
                                            <Row className='' key={itypevirtue}>
                                                <Col>
                                                    {itypevirtue === 0
                                                        ?                                                    
                                                            <Col xs={12} md={6}>
                                                                <div className="alert alert-success auto d-flex align-items-center justify-content-between" role="alert">
                                                                    {key === 0
                                                                        ?
                                                                            <>
                                                                                <h3 className='m-0 text-white'>{t('virtuestype.weak')}</h3>
                                                                                
                                                                            </>
                                                                        :
                                                                            <></>
                                                                    }
                                                                    {key === 1
                                                                        ?
                                                                            <>
                                                                                <h3 className='m-0 text-white'>{t('virtuestype.medium')}</h3>
                                                                                
                                                                            </>
                                                                        :
                                                                            <></>
                                                                    }
                                                                    {key === 2
                                                                        ?
                                                                            <>
                                                                                <h3 className='m-0 text-white'>{t('virtuestype.hard')}</h3>
                                                                                
                                                                            </>
                                                                        :
                                                                            <></>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        :
                                                            <></>
                                                    }
                                                    {typevirtue.length > 0 && typevirtue?.map((virtue, ivirtue) => (
                                                        <Fragment key={ivirtue}>                                                            
                                                            {allVirtuesExercises[virtue]?.length > 0 &&
                                                                <>
                                                                    <Row className='py-4 my-2'>
                                                                        <Col xs={12}>
                                                                            <OverlayTrigger trigger="click" placement="right"
                                                                                overlay={
                                                                                    <Popover>
                                                                                        <Popover.Header as="h3">{t('virtuetraining.comoentrenar')} {t('virtues.'+virtue)}</Popover.Header>
                                                                                        <Popover.Body>{t('virtuetraining.'+virtue)}</Popover.Body>
                                                                                    </Popover>
                                                                                }>
                                                                                <button type="button" className="alert alert-info mt-3 me-3 popovers d-flex justify-content-between align-items-center popover-training" data-toggle="popover">
                                                                                    {t('virtues.'+virtue)}
                                                                                    <i className='fas fa-plus ms-5'></i>
                                                                                </button>
                                                                            </OverlayTrigger>
                                                                        </Col>
                                                                    </Row>
                                                                    {allVirtuesExercises[virtue]?.map((exercise, iexercise) => (
                                                                        <Row key={iexercise} className='py-4 border rounded my-2'>                                                                              
                                                                            <Col xs={12} md={4} className='d-flex align-items-center justify-content-center py-3'>
                                                                                <p className='text-uppercase'><strong>{exercise.description}</strong></p>
                                                                            </Col>
                                                                            <Col xs={12} md={2} className='text-center d-block d-md-flex align-items-center py-3'>
                                                                                <p className='d-block d-md-none'>{t('training.level')}</p>
                                                                                <Form.Control
                                                                                    as='select'
                                                                                    className='form-select'
                                                                                    id={`level-${exercise._id}`}
                                                                                    name={`level-${exercise._id}`}
                                                                                    defaultValue={exercise.level}
                                                                                    onChange={(e) => changeExercise(e, exercise._id)}
                                                                                >                                                                                        
                                                                                    <option value='low'>{t('training.low')}</option>
                                                                                    <option value='medium'>{t('training.medium')}</option>
                                                                                    <option value='high'>{t('training.high')}</option>
                                                                                </Form.Control>
                                                                            </Col>
                                                                            <Col xs={12} md={2} className='text-center d-block d-md-flex align-items-center py-3'>
                                                                                <p className='d-block d-md-none'>{t('training.frequency')}</p>
                                                                                <Form.Control
                                                                                    as='select'
                                                                                    className='form-select'
                                                                                    id={`frequency-${exercise._id}`}
                                                                                    name={`frequency-${exercise._id}`}
                                                                                    defaultValue={exercise.frequency}
                                                                                    onChange={(e) => changeExercise(e, exercise._id)}
                                                                                >
                                                                                    <option value='daily'>{t('training.daily')}</option>
                                                                                    <option value='weekly'>{t('training.weekly')}</option>
                                                                                    <option value='monthly'>{t('training.monthly')}</option>
                                                                                    <option value='quarterly'>{t('training.quarterly')}</option>
                                                                                    <option value='annually'>{t('training.annually')}</option>
                                                                                </Form.Control>
                                                                            </Col>
                                                                            <Col xs={12} md={4} className='d-flex align-items-center justify-content-around py-3'>                                                                                                                                                    
                                                                                <Button className='btn-delete mx-1' onClick={(e) => deleteExercise(e, exercise._id)}><i className='fa fa-trash'></i></Button>
                                                                            </Col>
                                                                        </Row>
                                                                    ))}
                                                                </>
                                                            }
                                                        </Fragment>
                                                    ))}
                                                </Col>
                                            </Row>
                                        ))}
                                    </Card.Body>
                                </Card> 
                                :
                                <Fragment key={key}></Fragment>
                            ))}
                           
                            <Row className='my-4 botones-pasos'>
                                <Col className="d-flex justify-content-md-between justify-content-center flex-wrap">
                                    
                                            <Link to="/client/test/responsibility/weeklyresult">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.weekly')}
                                                </Button>
                                            </Link>
                                    
                                            <Link to="/client/test/responsibility/evaluation">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.evaluation')}
                                                </Button>
                                            </Link>
                                            <Link to="/client/test/responsibility/training">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.training')}
                                                </Button>
                                            </Link>
                                    
                                            <Link to="/client/test/responsibility/historical">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.historical')}
                                                </Button>
                                            </Link>
                                            
                                            <Button className='boton-blanco' variant='primary' type="submit">
                                                {t('responsibility.essential')}
                                            </Button>
                                            
                                </Col>
                            </Row>
                            <Row className='my-4 botones-pasos'>
                                <Col className="d-flex justify-content-center flex-wrap">
                                    <Link to="/client/test/responsibility/guide">
                                        <Button variant='primary guide' type="submit" disabled>
                                            {t('responsibility.guide')}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>       
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}