import React from 'react'
import {Navbar,Nav,Container} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../context/auth/useAuth'


export default function MenuGeneral({background='transparent'}) {
	const { t } = useTranslation()     
	const {client,logout} = useAuth()
  	return (
		<Navbar expand="xs" fixed="top" id="menu-public" className='py-4 mb-5' style={{backgroundColor:background}}>
			<a href="/">
				<img
					src="/images/logotipo-invertido.png"
					width="150"                
					className="d-inline-block align-top justify-center"
					alt="Logotipo Motif"
					id="logo-menu"
				/>		
			</a>
			<Container fluid>
				<Navbar.Brand href="#home" className="m-auto">  
					
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" className='custom-toggler' />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="text-end">						
						<Nav.Link href="#home">{t('menu.aboutus')}</Nav.Link>
						<Nav.Link href="#link">{t('menu.methodmotif')}</Nav.Link>
						<Nav.Link href="#link">{t('menu.evaluate-well-being')}</Nav.Link>
						<Nav.Link href="#link">{t('menu.development-itinerary')}</Nav.Link>
						<Nav.Link href="#link">{t('menu.testimonials')}</Nav.Link>						
						{
							client
							?								
								<>
								{ client.tests !== 'all' 
								?
									<Nav.Link href="/client/profile">{t('menu.private-area')}</Nav.Link>
								:
									<Nav.Link href="/admin/dashboard">{t('menu.private-area')}</Nav.Link>
								}
									<Nav.Link href="#" onClick={logout}>{t('menu.logout')}</Nav.Link>
								</>
							:
								<Nav.Link href="/auth">{t('menu.login')}</Nav.Link>
						}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}
