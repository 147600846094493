import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Container, Row, Col, Button, Card, Table, Form} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { getUservirtue } from '../../../../actions/uservirtueActions'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { sendEvaluationTest } from '../../../../actions/evaluationTestActions'

export default function Evaluation() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {client} = useAuth()

    const {uservirtue} = useSelector(state=>state.uservirtue)
    const [weakVirtues, setWeakVirtues] = useState([])
    const [mediumVirtues, setMediumVirtues] = useState([])
    const [hardVirtues, setHardVirtues] = useState([])
    const [step, setStep] = useState(1)

    const emotionsAllType = {
        'aceptacion': ['decepcionado', 'defraudado', 'frustrado', 'obstinarse'],
        'admiracion': ['envidia', 'criticar', 'menospreciar'],
        'alegria': ['melancolico', 'nostalgico', 'triste', 'celebrar'],
        'compania': ['invadido', 'aislarse'],
        'compasion': ['sentirrechazo', 'alejarse', 'causardolor'],
        'dignidad': ['culpable', 'compararsevalorpropio', 'herido', 'ignorado', 'incompetente', 'insuficiente', 'juzgado', 'menospreciado', 'boicotearse', 'descalificarse', 'sabotearse'],
        'disciplina': ['perezoso', 'preocupado', 'desidia', 'postergar', 'procrastinar'],
        'educacion': ['indiferenciaeducacion', 'atropellar', 'gritar', 'insultar', 'romperreglas'],
        'generosidad': ['escasez', 'robado', 'compartir'],
        'guia': ['desorientado', 'perdido', 'conductaerratica'],
        'humildad': ['incomprendido', 'superior', 'descalificar', 'despreciar', 'devaluar', 'juzgar'],
        'identidad': ['comparado', 'inadecuado', 'vergüenza', 'compararse', 'mentir'],
        'inspiracion': ['aburrido', 'creativo', 'crearcosas', 'rutina', 'serespontaneo'],
        'justicia': ['abusado', 'injusticia', 'usado', 'castigar', 'darrecibir', 'encubrir', 'ponerlimites'],
        'lealtad': ['descomprometido', 'desconfiado', 'compromisos', 'infidelidad', 'traicionar'],
        'libertad': ['controlado', 'dependiente', 'frenado', 'limitado', 'manipulado', 'controlar', 'esclavizar', 'imponer', 'limitarme', 'manipular', 'obedecer'],
        'paciencia': ['desesperado', 'impaciente', 'apresurar', 'apresurarme', 'desesperarse', 'presionar'],
        'perdon': ['rencoroso', 'vengativo', 'deseardolor', 'vengarse'],
        'perseverancia': ['derrotado', 'rendirse', 'vencido'],
        'proteccion': ['desprotegido', 'indefenso', 'vulnerable', 'correrriesgos', 'preverriesgos'],
        'responsabilidad': ['indiferenciaresponsabilidad', 'abdicar', 'acusar', 'culpables', 'evadir'],
        'sabiduria': ['confusion', 'curiosidad'],
        'valentia': ['paralizado', 'riesgo', 'detenerse']
    }

    const [emotionsSelect, setEmotionsSelect] = useState({
        'aceptacion': ['decepcionado', 'defraudado', 'frustrado', 'obstinarse'],
        'admiracion': ['envidia', 'criticar', 'menospreciar'],
        'alegria': ['melancolico', 'nostalgico', 'triste', 'celebrar'],
        'compania': ['invadido', 'aislarse'],
        'compasion': ['sentirrechazo', 'alejarse', 'causardolor'],
        'dignidad': ['culpable', 'compararsevalorpropio', 'herido', 'ignorado', 'incompetente', 'insuficiente', 'juzgado', 'menospreciado', 'boicotearse', 'descalificarse', 'sabotearse'],
        'disciplina': ['perezoso', 'preocupado', 'desidia', 'postergar', 'procrastinar'],
        'educacion': ['indiferenciaeducacion', 'atropellar', 'gritar', 'insultar', 'romperreglas'],
        'generosidad': ['escasez', 'robado', 'compartir'],
        'guia': ['desorientado', 'perdido', 'conductaerratica'],
        'humildad': ['incomprendido', 'superior', 'descalificar', 'despreciar', 'devaluar', 'juzgar'],
        'identidad': ['comparado', 'inadecuado', 'vergüenza', 'compararse', 'mentir'],
        'inspiracion': ['aburrido', 'creativo', 'crearcosas', 'rutina', 'serespontaneo'],
        'justicia': ['abusado', 'injusticia', 'usado', 'castigar', 'darrecibir', 'encubrir', 'ponerlimites'],
        'lealtad': ['descomprometido', 'desconfiado', 'compromisos', 'infidelidad', 'traicionar'],
        'libertad': ['controlado', 'dependiente', 'frenado', 'limitado', 'manipulado', 'controlar', 'esclavizar', 'imponer', 'limitarme', 'manipular', 'obedecer'],
        'paciencia': ['desesperado', 'impaciente', 'apresurar', 'apresurarme', 'desesperarse', 'presionar'],
        'perdon': ['rencoroso', 'vengativo', 'deseardolor', 'vengarse'],
        'perseverancia': ['derrotado', 'rendirse','vencido'],
        'proteccion': ['desprotegido', 'indefenso', 'vulnerable', 'correrriesgos', 'preverriesgos'],
        'responsabilidad': ['indiferenciaresponsabilidad', 'abdicar', 'acusar', 'culpables', 'evadir'],
        'sabiduria': ['confusion', 'curiosidad'],
        'valentia': ['paralizado', 'riesgo', 'detenerse']
    })

    const [emotionsNotInSelect, setEmotionsNotInSelect] = useState({
        'aceptacion': [],
        'admiracion': [],
        'alegria': [],
        'compania': [],
        'compasion': [],
        'dignidad': [],
        'disciplina': [],
        'educacion': [],
        'generosidad': [],
        'guia': [],
        'humildad': [],
        'identidad': [],
        'inspiracion': [],
        'justicia': [],
        'lealtad': [],
        'libertad': [],
        'paciencia': [],
        'perdon': [],
        'perseverancia': [],
        'proteccion': [],
        'responsabilidad': [],
        'sabiduria': [],
        'valentia': [],
    })

    const [schemaEvaluation, setSchemaEvaluation] = useState({
        'aceptacion': {},
        'admiracion': {},
        'alegria': {},
        'compania': {},
        'compasion': {},
        'dignidad': {},
        'disciplina': {},
        'educacion': {},
        'generosidad': {},
        'guia': {},
        'humildad': {},
        'identidad': {},
        'inspiracion': {},
        'justicia': {},
        'lealtad': {},
        'libertad': {},
        'paciencia': {},
        'perdon': {},
        'perseverancia': {},
        'proteccion': {},
        'responsabilidad': {},
        'sabiduria': {},
        'valentia': {}
    })

    const [allNoneRequired, setAllNoneRequired] = useState({})

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client){
            dispatch(getUservirtue(client.id))
        }
    }, [client, dispatch])

    useEffect(() => {
        if(uservirtue){
            let weakVirtuesArray = []
            let mediumVirtuesArray = []
            let hardVirtuesArray = []
            let nonejson = {}

            for (const virtue in uservirtue) {
                if(virtue !== '_id' && virtue !== '__v' && virtue !== 'user'){
                    if(uservirtue[virtue] === 'debil'){
                        weakVirtuesArray.push(virtue)
                        nonejson[virtue] = 'nada'
                    }
                    if(uservirtue[virtue] === 'media'){
                        mediumVirtuesArray.push(virtue)
                    }
                    if(uservirtue[virtue] === 'fuerte'){
                        hardVirtuesArray.push(virtue)
                    }
                }
            }

            setWeakVirtues(weakVirtuesArray)
            setMediumVirtues(mediumVirtuesArray)
            setHardVirtues(hardVirtuesArray)
            setAllNoneRequired(nonejson)
        }
    }, [uservirtue])

    const changerEmotionBehavior = async(virtue, emotion) => {
        if(emotion.target.value !== 'ninguno'){
            if(emotionsNotInSelect[virtue].length < 3){
                let emotionsForSelect = emotionsSelect[virtue]?.filter((item) => item !== emotion.target.value)
                
                setEmotionsSelect({...emotionsSelect, [virtue]: emotionsForSelect})
                setEmotionsNotInSelect({...emotionsNotInSelect, [virtue]: [...emotionsNotInSelect[virtue], emotion.target.value]})
                setSchemaEvaluation({...schemaEvaluation, [virtue]: {...schemaEvaluation[virtue], [emotion.target.value]: 'ninguno'}})
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'error',
                    text: t('evaluation.3alreadyselected')
                })
            }
            
            if(step === 1){
                document.getElementById('virtue_select_'+virtue).value = '0'
                setAllNoneRequired({...allNoneRequired, [virtue]: 'ninguno'})
            }
        }else{
            let copyEmotionsNotInSelect = {...emotionsNotInSelect}
            let copyEmotionsSelect = {...emotionsSelect}

            if (emotionsNotInSelect[virtue].length > 0) {
                emotionsAllType[virtue]?.map(async(emotionin) => (
                    delete schemaEvaluation[virtue][emotionin]
                ))
                copyEmotionsNotInSelect = {...copyEmotionsNotInSelect, [virtue]: []}
                copyEmotionsSelect = {...copyEmotionsSelect, [virtue]: emotionsAllType[virtue]}
            }

            setEmotionsNotInSelect(copyEmotionsNotInSelect)
            setEmotionsSelect(copyEmotionsSelect)
            document.getElementById('virtue_select_'+virtue).value = 'ninguno'
            setAllNoneRequired({...allNoneRequired, [virtue]: 'ninguno'})
        }
    }

    const removeEmotionBehavior = (virtue, emotion) => {
        let emotionsForInSelect = emotionsNotInSelect[virtue]?.filter((item) => item !== emotion)
        delete schemaEvaluation[virtue][emotion]
        setEmotionsNotInSelect({...emotionsNotInSelect, [virtue]: emotionsForInSelect})
        setEmotionsSelect({...emotionsSelect, [virtue]: [...emotionsSelect[virtue], emotion]})
        if(Object.keys(schemaEvaluation[virtue]).length > 0){
            setAllNoneRequired({...allNoneRequired, [virtue]: 'ninguno'})
        }else{
            setAllNoneRequired({...allNoneRequired, [virtue]: 'nada'})
        }
    }

    const changerRadio = (virtue, emotion, radio) => {
        setSchemaEvaluation({...schemaEvaluation, [virtue]: {...schemaEvaluation[virtue], [emotion]: radio}})
    }

    // const setAllNull = async(virtueType) => {
    //     let arrayVirtueType = []
    //     if(virtueType === 'media'){
    //         arrayVirtueType = mediumVirtues
    //     }
    //     if(virtueType === 'fuerte'){
    //         arrayVirtueType = hardVirtues
    //     }

    //     let copyEmotionsNotInSelect = {...emotionsNotInSelect}
    //     let copyEmotionsSelect = {...emotionsSelect}
    //     Promise.all(await arrayVirtueType?.map(async(virtue) => {
    //         if (emotionsNotInSelect[virtue].length > 0) {
    //             emotionsAllType[virtue]?.map(async(emotion) => (
    //                 delete schemaEvaluation[virtue][emotion]
    //             ))
    //             copyEmotionsNotInSelect = {...copyEmotionsNotInSelect, [virtue]: []}
    //             copyEmotionsSelect = {...copyEmotionsSelect, [virtue]: emotionsAllType[virtue]}
    //         }
    //         return true
    //     })).then(()=>{
    //         setEmotionsNotInSelect(copyEmotionsNotInSelect)
    //         setEmotionsSelect(copyEmotionsSelect)
    //     })
    // }

    const handleNext = async(e) => {
        e.preventDefault()

        if(step === 1){
            let allNone = 1
            for (const key in allNoneRequired) {
                if(allNoneRequired[key] === 'nada'){
                    allNone = 0
                }
            }

            if(allNone === 1){
                setStep(step+1)
                let element = document.querySelector("#evaluation_test_header")
                element.scrollIntoView({ behavior: 'smooth', block: 'end'})
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    confirmButtonText: t('evaluation.itsok'),
                    icon: 'warning',
                    text: t('evaluation.notallsomething')
                })
            }
        }else{
            if(step < 3){
                setStep(step+1)
                let element = document.querySelector("#evaluation_test_header")
                element.scrollIntoView({ behavior: 'smooth', block: 'end'})
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    showDenyButton: true,
                    confirmButtonText: t('evaluation.letsgo'),
                    denyButtonText: t('evaluation.cancel'),
                    icon: 'warning',
                    text: t('evaluation.sendevaluation')
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(sendEvaluationTest(client.id, schemaEvaluation))
                        Swal.fire(t('evaluation.successfullysend'), '', 'success')
                        navigate('/client/test/responsibility/historical')
                    }
                })
            }
        }
    }

    return (
        <>
            <Container fluid className='contenido modulo2'>
                <Row className='px-0 px-md-5'>
                    <Col xs="12" className='mx-auto'>
                        <div className="form p-2 p-md-4">
                            <div id="evaluation_test_header">
                                <Row>
                                    <Col className='px-4 d-flex'>
                                        <h2 className='verde'>{t('evaluation.title')}</h2>
                                        <Link to="/client/profile" className='position-fixed icono-home'>
                                            <i class="fas fa-home fa-2x"></i>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='px-4 pb-3'>
                                        <div className="alert auto alert-success text-center" role="alert">
                                            <h3 className='m-0 text-white'>{t('evaluation.alert')}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='px-4'>
                                        <p className='my-0'><strong>{t('evaluation.paragraph1')}</strong></p>
                                        <p className='my-0'><strong>{t('evaluation.paragraph2')}</strong></p>
                                    </Col>
                                </Row>
                            </div>
                            <Card className='my-4'>
                                <Card.Body className='px-2 px-md-5'>
                                    <div style={step === 1 ? null : {display: "none"}}>
                                        <Row>
                                            <Col xs="auto" className='px-4 pt-4 py-2'>
                                                <div className="alert auto alert-success text-center" role="alert">
                                                    <h3 className='m-0 text-white'>{t('virtuestype.weak')}</h3>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                        {weakVirtues?.map((virtue, ivirtue) => (
                                            <Col xs={12} key={ivirtue}>
                                                <h3 className='ps-3 border-bottom'>{t('virtues.'+virtue)}</h3>
                                                <Table className="table-frequency">
                                                    <tbody>
                                                        <tr className="text-center">
                                                            <td className='form-control-evaluation-td'>
                                                                <Form.Control
                                                                    as="select"
                                                                    id={`virtue_select_${virtue}`}
                                                                    name={`virtue_select_${virtue}`}
                                                                    onChange={(e) => changerEmotionBehavior(virtue, e)}
                                                                    defaultValue='0'
                                                                    className='form-select form-control-verde'
                                                                >
                                                                    <option value='0'>{t('emotionsbehaviors.select')}</option>
                                                                    <option value='ninguno'>{t('emotionsbehaviors.none')}</option>
                                                                    {emotionsSelect[virtue]?.map((emotion, iemotion)=>(
                                                                        <option key={iemotion} value={emotion}>{t('emotionsbehaviors.'+emotion)}</option>
                                                                    ))}
                                                                </Form.Control>
                                                            </td>
                                                            <td className='d-none d-md-table-cell'><h4>{t('evaluation.daily')}</h4></td>
                                                            <td className='d-none d-md-table-cell'><h4>{t('evaluation.somedays')}</h4></td>
                                                            <td className='d-none d-md-table-cell'><h4>{t('evaluation.fewdays')}</h4></td>                                                                
                                                        </tr>
                                                        {emotionsNotInSelect[virtue]?.map((emotion, iemotion)=>(
                                                            <tr key={iemotion} className="customizedcolor">
                                                                <td className='d-none d-md-table-cell'>
                                                                    <Button className='btn btn-secondary' onClick={() => removeEmotionBehavior(virtue, emotion)}><i className='fas fa-trash'></i></Button> <strong className='text-uppercase'>{t('emotionsbehaviors.'+emotion)}</strong>
                                                                </td>
                                                                <td className='text-center d-none d-md-table-cell'>
                                                                    <strong className='d-block d-md-none'>{t('evaluation.daily')}</strong>
                                                                    <input type="radio" name={`radio_${virtue}_${emotion}`} className="form-check-input-color radio-naranja" checked={schemaEvaluation[virtue][emotion] === 'diario' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'diario')}></input>
                                                                </td>
                                                                <td className='text-center d-none d-md-table-cell'>
                                                                    <input type="radio" name={`radio_${virtue}_${emotion}`} className="form-check-input-color radio-amarillo" checked={schemaEvaluation[virtue][emotion] === 'algunos' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'algunos')}></input>
                                                                </td>
                                                                <td className='text-center d-none d-md-table-cell'>
                                                                    <input type="radio" name={`radio_${virtue}_${emotion}`} className="form-check-input-color radio-verde" checked={schemaEvaluation[virtue][emotion] === 'pocos' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'pocos')}></input>
                                                                </td>
                                                                <td className='d-table-cell d-md-none pt-3'> 
                                                                    <i className='fas fa-trash' onClick={() => removeEmotionBehavior(virtue, emotion)}></i> <strong className='text-uppercase'>{t('emotionsbehaviors.'+emotion)}</strong>
                                                                    <div className='d-flex justify-content-between mt-2'>
                                                                        <div className='d-flex flex-column align-content-between justify-content-center'>
                                                                            <span className='d-block d-md-none'>{t('evaluation.daily')}</span>
                                                                            <input type="radio" name={`radio_${virtue}_${emotion}2`} className="form-check-input-color radio-naranja mx-auto" checked={schemaEvaluation[virtue][emotion] === 'diario' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'diario')}></input>
                                                                        </div>
                                                                        <div className='d-flex flex-column align-content-between justify-content-center'>
                                                                            <span className='d-block d-md-none'>{t('evaluation.somedays')}</span>
                                                                            <input type="radio" name={`radio_${virtue}_${emotion}2`} className="form-check-input-color radio-amarillo mx-auto" checked={schemaEvaluation[virtue][emotion] === 'algunos' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'algunos')}></input>
                                                                        </div>
                                                                        <div className='d-flex flex-column align-content-between justify-content-center '>
                                                                            <span className='d-block d-md-none'>{t('evaluation.fewdays')}</span>
                                                                            <input type="radio" name={`radio_${virtue}_${emotion}2`} className="form-check-input-color radio-verde mx-auto" checked={schemaEvaluation[virtue][emotion] === 'pocos' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'pocos')}></input>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Col>                                        
                                        ))}
                                        </Row>
                                    </div>
                                    <div style={step === 2 ? null : {display: "none"}}>
                                        <Row>
                                            <Col xs="auto" className='px-4 pt-4 py-2'>
                                                <div className="alert auto alert-success text-center" role="alert">
                                                    <h3 className='m-0 text-white'>{t('virtuestype.medium')}</h3>
                                                </div>
                                            </Col>
                                            {/* <Col xs="auto" className='px-4 pb-3 ms-auto'>
                                                <Button className="alert auto boton-gris text-center" role="alert" onClick={() => setAllNull('media')}>
                                                    <h4 className='m-0 text-white'>{t('evaluation.setnull')}</h4>
                                                </Button>
                                            </Col> */}
                                        </Row>
                                        <Row>
                                        {mediumVirtues?.map((virtue, ivirtue) => (
                                                <Col xs={12} key={ivirtue}>
                                                    <h3 className='ps-3 border-bottom'>{t('virtues.'+virtue)}</h3>
                                                    <Table className="table-frequency">
                                                        <tbody>
                                                            <tr className="text-center">
                                                                <td className='form-control-evaluation-td'>
                                                                    <Form.Control
                                                                        as="select"
                                                                        id={`virtue_select_${virtue}`}
                                                                        name={`virtue_select_${virtue}`}
                                                                        onChange={(e) => changerEmotionBehavior(virtue, e)}
                                                                        value='0'
                                                                        className='form-select form-control-verde'
                                                                    >
                                                                        <option value=''>{t('emotionsbehaviors.select')}</option>
                                                                        <option value='ninguno'>{t('emotionsbehaviors.none')}</option>
                                                                        {emotionsSelect[virtue]?.map((emotion, iemotion)=>(
                                                                            <option key={iemotion} value={emotion}>{t('emotionsbehaviors.'+emotion)}</option>
                                                                        ))}
                                                                    </Form.Control>
                                                                </td>
                                                                <td className='d-none d-md-table-cell'><h4>{t('evaluation.daily')}</h4></td>
                                                                <td className='d-none d-md-table-cell'><h4>{t('evaluation.somedays')}</h4></td>
                                                                <td className='d-none d-md-table-cell'><h4>{t('evaluation.fewdays')}</h4></td>  
                                                            </tr>
                                                            {emotionsNotInSelect[virtue]?.map((emotion, iemotion)=>(
                                                                <tr key={iemotion} className="customizedcolor">
                                                                    <td className='d-none d-md-table-cell'>                                                                        
                                                                        <Button className='btn btn-secondary' onClick={() => removeEmotionBehavior(virtue, emotion)}><i className='fas fa-trash'></i></Button> <strong className='text-uppercase'>{t('emotionsbehaviors.'+emotion)}</strong>
                                                                    </td>
                                                                    <td className='text-center d-none d-md-table-cell'>
                                                                        <input type="radio" name={`radio_${virtue}_${emotion}`} className="form-check-input-color radio-naranja" checked={schemaEvaluation[virtue][emotion] === 'diario' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'diario')}></input>
                                                                    </td>
                                                                    <td className='text-center d-none d-md-table-cell'>
                                                                        <input type="radio" name={`radio_${virtue}_${emotion}`} className="form-check-input-color radio-amarillo" checked={schemaEvaluation[virtue][emotion] === 'algunos' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'algunos')}></input>
                                                                    </td>
                                                                    <td className='text-center d-none d-md-table-cell'>
                                                                        <input type="radio" name={`radio_${virtue}_${emotion}`} className="form-check-input-color radio-verde" checked={schemaEvaluation[virtue][emotion] === 'pocos' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'pocos')}></input>
                                                                    </td>
                                                                    <td className='d-table-cell d-md-none pt-3'> 
                                                                        <i className='fas fa-trash' onClick={() => removeEmotionBehavior(virtue, emotion)}></i> <strong className='text-uppercase'>{t('emotionsbehaviors.'+emotion)}</strong>
                                                                        <div className='d-flex justify-content-between mt-2'>
                                                                            <div className='d-flex flex-column align-content-between justify-content-center'>
                                                                                <span className='d-block d-md-none'>{t('evaluation.daily')}</span>
                                                                                <input type="radio" name={`radio_${virtue}_${emotion}2`} className="form-check-input-color radio-naranja mx-auto" checked={schemaEvaluation[virtue][emotion] === 'diario' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'diario')}></input>
                                                                            </div>
                                                                            <div className='d-flex flex-column align-content-between justify-content-center'>
                                                                                <span className='d-block d-md-none'>{t('evaluation.somedays')}</span>
                                                                                <input type="radio" name={`radio_${virtue}_${emotion}2`} className="form-check-input-color radio-amarillo mx-auto" checked={schemaEvaluation[virtue][emotion] === 'algunos' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'algunos')}></input>
                                                                            </div>
                                                                            <div className='d-flex flex-column align-content-between justify-content-center '>
                                                                                <span className='d-block d-md-none'>{t('evaluation.fewdays')}</span>
                                                                                <input type="radio" name={`radio_${virtue}_${emotion}2`} className="form-check-input-color radio-verde mx-auto" checked={schemaEvaluation[virtue][emotion] === 'pocos' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'pocos')}></input>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </Col>                                                
                                            
                                        ))}
                                        </Row>
                                    </div>
                                    <div style={step === 3 ? null : {display: "none"}}>
                                        <Row>
                                            <Col xs="auto" className='px-4 pt-4 py-2'>
                                                <div className="alert auto alert-success text-center" role="alert">
                                                    <h3 className='m-0 text-white'>{t('virtuestype.hard')}</h3>
                                                </div>
                                            </Col>
                                            {/* <Col xs="auto" className='px-4 pb-3 ms-auto'>
                                                <Button className="alert auto boton-gris text-center" role="alert" onClick={() => setAllNull('fuerte')}>
                                                    <h4 className='m-0 text-white'>{t('evaluation.setnull')}</h4>
                                                </Button>
                                            </Col> */}
                                        </Row>
                                        <Row>
                                        {hardVirtues?.map((virtue, ivirtue) => (                                            
                                                <Col xs={12} key={ivirtue}>
                                                    <h3 className='ps-3 border-bottom'>{t('virtues.'+virtue)}</h3>
                                                    <Table className="table-frequency">
                                                        <tbody>
                                                            <tr className="text-center">
                                                                <td className='form-control-evaluation-td'>
                                                                    <Form.Control
                                                                        as="select"
                                                                        id={`virtue_select_${virtue}`}
                                                                        name={`virtue_select_${virtue}`}
                                                                        onChange={(e) => changerEmotionBehavior(virtue, e)}
                                                                        value='0'
                                                                        className='form-select form-control-verde'
                                                                    >
                                                                        <option value=''>{t('emotionsbehaviors.select')}</option>
                                                                        <option value='ninguno'>{t('emotionsbehaviors.none')}</option>
                                                                        {emotionsSelect[virtue]?.map((emotion, iemotion)=>(
                                                                            <option key={iemotion} value={emotion}>{t('emotionsbehaviors.'+emotion)}</option>
                                                                        ))}
                                                                    </Form.Control>
                                                                </td>
                                                                <td className='d-none d-md-table-cell'><h4>{t('evaluation.daily')}</h4></td>
                                                                <td className='d-none d-md-table-cell'><h4>{t('evaluation.somedays')}</h4></td>
                                                                <td className='d-none d-md-table-cell'><h4>{t('evaluation.fewdays')}</h4></td>  
                                                            </tr>
                                                            {emotionsNotInSelect[virtue]?.map((emotion, iemotion)=>(
                                                                <tr key={iemotion} className="customizedcolor">
                                                                    <td className='d-none d-md-table-cell'>                                                                        
                                                                        <Button className='btn btn-secondary' onClick={() => removeEmotionBehavior(virtue, emotion)}><i className='fas fa-trash'></i></Button> <strong className='text-uppercase'>{t('emotionsbehaviors.'+emotion)}</strong>
                                                                    </td>
                                                                    <td className='text-center d-none d-md-table-cell'>
                                                                        <input type="radio" name={`radio_${virtue}_${emotion}`} className="form-check-input-color radio-naranja" checked={schemaEvaluation[virtue][emotion] === 'diario' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'diario')}></input>
                                                                    </td>
                                                                    <td className='text-center d-none d-md-table-cell'>
                                                                        <input type="radio" name={`radio_${virtue}_${emotion}`} className="form-check-input-color radio-amarillo" checked={schemaEvaluation[virtue][emotion] === 'algunos' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'algunos')}></input>
                                                                    </td>
                                                                    <td className='text-center d-none d-md-table-cell'>
                                                                        <input type="radio" name={`radio_${virtue}_${emotion}`} className="form-check-input-color radio-verde" checked={schemaEvaluation[virtue][emotion] === 'pocos' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'pocos')}></input>
                                                                    </td>
                                                                    <td className='d-table-cell d-md-none pt-3'> 
                                                                        <i className='fas fa-trash' onClick={() => removeEmotionBehavior(virtue, emotion)}></i> <strong className='text-uppercase'>{t('emotionsbehaviors.'+emotion)}</strong>
                                                                        <div className='d-flex justify-content-between mt-2'>
                                                                            <div className='d-flex flex-column align-content-between justify-content-center'>
                                                                                <span className='d-block d-md-none'>{t('evaluation.daily')}</span>
                                                                                <input type="radio" name={`radio_${virtue}_${emotion}2`} className="form-check-input-color radio-naranja mx-auto" checked={schemaEvaluation[virtue][emotion] === 'diario' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'diario')}></input>
                                                                            </div>
                                                                            <div className='d-flex flex-column align-content-between justify-content-center'>
                                                                                <span className='d-block d-md-none'>{t('evaluation.somedays')}</span>
                                                                                <input type="radio" name={`radio_${virtue}_${emotion}2`} className="form-check-input-color radio-amarillo mx-auto" checked={schemaEvaluation[virtue][emotion] === 'algunos' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'algunos')}></input>
                                                                            </div>
                                                                            <div className='d-flex flex-column align-content-between justify-content-center '>
                                                                                <span className='d-block d-md-none'>{t('evaluation.fewdays')}</span>
                                                                                <input type="radio" name={`radio_${virtue}_${emotion}2`} className="form-check-input-color radio-verde mx-auto" checked={schemaEvaluation[virtue][emotion] === 'pocos' ? 'checked' : ''} onChange={(e) => changerRadio(virtue, emotion, 'pocos')}></input>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            
                                        ))}
                                        </Row>
                                    </div>
                                    <Row xs="12" id="evaluation_test_footer_right">
                                        <Col className="text-end mt-2 mt-md-0">
                                            <Button variant='primary' type="submit" onClick={handleNext}>
                                                {step === 3 ? t('global.save') : t('global.next')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Row className='my-4 botones-pasos'>
                                <Col className="d-flex justify-content-md-between justify-content-center flex-wrap">
                                    
                                    <Link to="/client/test/responsibility/weeklyresult">
                                        <Button className='boton-gris' variant='primary' type="submit">
                                            {t('responsibility.weekly')}
                                        </Button>
                                    </Link>
                            
                                    <Button className='boton-blanco' variant='primary' type="submit">
                                        {t('responsibility.evaluation')}
                                    </Button>
                            
                                    <Link to="/client/test/responsibility/training">
                                        <Button className='boton-gris' variant='primary' type="submit">
                                            {t('responsibility.training')}
                                        </Button>
                                    </Link>
                            
                                    <Link to="/client/test/responsibility/historical">
                                        <Button className='boton-gris' variant='primary' type="submit">
                                            {t('responsibility.historical')}
                                        </Button>
                                    </Link>

                                    <Link to="/client/test/responsibility/essential">
                                        <Button className='boton-gris' variant='primary' type="submit">
                                            {t('responsibility.essential')}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                            <Row className='my-4 botones-pasos'>
                                <Col className="d-flex justify-content-center flex-wrap">
                                    <Link to="/client/test/responsibility/guide">
                                        <Button variant='primary guide' type="submit" disabled>
                                            {t('responsibility.guide')}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}