import React, {useState} from 'react'
import axios from 'axios';
import { useNavigate, useParams  } from 'react-router-dom';
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

const ResetPassword = (props) => {

    const { t } = useTranslation()

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState("");
    const [password, setPassword] = useState("");

    const { id } = useParams();
    const { tokenresetpassword } = useParams();

    /**
     * Captura el submit del formulario y cambia la clave de la cuenta
     * @param {*} e 
     */
    const handleSubmit = async(e) => {
        e.preventDefault();

        const clientPassword = {
            password: e.target.password.value
        }        
           
        setIsLoading(true);

        await axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/forgot/resetpassword?id=' + id + '&token=' + tokenresetpassword, clientPassword, {
            where: {
                id: id,
                tokenresetpassword: tokenresetpassword
            }
        })
        .then((res) => {            
            setIsLoading(false)

            // Mostramos la notificación
            Swal.fire({
                showConfirmButton: true,
                icon: 'success',
                text: 'Contraseña cambiada con éxito'
            })
            
            // Redirigimos a la raíz
            navigate('/')

        }).catch((err) => {
            setIsLoading(false)
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text: 'Ha habido un error al intentar enviar los datos, vuelva a intentarlo más tarde'
            })
        });
    }

    /**
     * Comprueba ambas contraseñas coinciden
     * @param {*} e 
     */
    const checkValidation = (e) => {
        const confirmPass = e.target.value;        

        if (password !== confirmPass) {
            setIsError("Las contraseñas no coinciden");
        }
        else {
            setIsError("");
        }
    }

    return (
        <Container fluid className='login d-flex align-items-center'>
            <div className='bloque-login'>
                <Row>
                    <Col className='mx-auto text-center mb-5'>
                        <a href="/">
                            <img
                                src="/images/logotipo.png"
                                width="150"
                                className="d-inline-block align-top justify-center"
                                alt="Logotipo Motif"
                            />
                        </a>
                    </Col>
                </Row>
                <Row className='px-3 px-md-5 cuadro'>
                    <Col className='mx-auto'>
                        <Form className="form p-4" id="kt_password_reset_form" onSubmit={handleSubmit}>
                            <div className="text-center mb-5">
                                <h1 className="mb-3">{t('passwordreset.question')}</h1>
                                <div className="text-gray-400 fw-bold fs-4">{t('passwordreset.help')}</div>
                            </div>
                            <div className="fv-row mb-3">
                                <Form.Label className="form-label">{t('passwordreset.password_label')}</Form.Label>
                                <Form.Control type="password" placeholder="" name="password" autoComplete="off" onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="fv-row mb-3">
                                <Form.Label className="fw-bolder text-gray-900 fs-6">{t('passwordreset.confirm_password')}</Form.Label>
                                <Form.Control type="password" placeholder="" name="confirm_password" autoComplete="off" onChange={checkValidation}/>
                                {/* Mostramos si coinciden o no las contraseñas */}
                                {isError
                                    ?
                                    <div className="fv-plugins-message-container invalid-feedback"><div data-field="name" data-validator="notEmpty">{isError}</div></div>
                                    :
                                    ""
                                }
                            </div>
                            <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                            <Button variant="primary" type="submit" id="kt_password_reset_submit" className="btn btn-lg fw-bolder me-4 ">
                                {/* Mostramos un loader mientras se realiza la petición */}
                                {isLoading
                                ?
                                <span className="indicator-label">
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                :
                                <span className="indicator-label">{t('forgot.submit')}</span>
                                }
                                </Button>
                                <Link to={`/`} className="btn btn-lg btn-light-primary fw-bolder">{t('passwordreset.cancel')}</Link>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default ResetPassword;