import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Container, Row, Col, Form, Button, Card, OverlayTrigger, Popover} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getUservirtue } from '../../../../actions/uservirtueActions'
import { getAllEvaluationTest } from '../../../../actions/evaluationTestActions'
import { Fragment } from 'react'
import { editTrainingTest, getAllTrainingTest, sendTrainingTest, setdoneTrainingTest } from '../../../../actions/trainingTestActions'
import confetti from 'canvas-confetti'
import 'add-to-calendar-button'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Report from "./trainingreport"

export default function Training() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const {uservirtue} = useSelector(state=>state.uservirtue)
    const {tests} = useSelector(state=>state.evaluationTest)
    const {exercises} = useSelector(state=>state.trainingTest)

    const [weakVirtues, setWeakVirtues] = useState([])
    const [mediumVirtues, setMediumVirtues] = useState([])
    const [hardVirtues, setHardVirtues] = useState([])
    const today = new Date().toISOString().split('T')[0]

    const [allVirtuesState, setAllVirtuesState] = useState([[], [], [], [], [], [], [], [], [], [], [], []])
    const [allVirtuesWeak, setAllVirtuesWeak] = useState([[], [], [], []])
    const [allVirtuesMedium, setAllVirtuesMedium] = useState([[], [], [], []])
    const [allVirtuesHard, setAllVirtuesHard] = useState([[], [], [], []])
    const [allVirtuesExercises, setAllVirtuesExercises] = useState({
        'aceptacion': [], 'admiracion': [], 'alegria': [], 'compania': [], 'compasion': [], 'dignidad': [], 'disciplina': [],
        'educacion': [], 'generosidad': [], 'guia': [], 'humildad': [], 'identidad': [], 'inspiracion': [], 'justicia': [],
        'lealtad': [], 'libertad': [], 'paciencia': [], 'perdon': [], 'perseverancia': [], 'proteccion': [], 'responsabilidad': [],
        'sabiduria': [], 'valentia': [],
    })
    const [lastUpdate, setLastUpdate] = useState(null)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client){
            dispatch(getUservirtue(client.id))
            dispatch(getAllEvaluationTest(client.id))
            dispatch(getAllTrainingTest(client.id))
        }
    }, [client, dispatch])

    useEffect(() => {
        if(uservirtue){
            let weakVirtuesArray = []
            let mediumVirtuesArray = []
            let hardVirtuesArray = []

            for (const virtue in uservirtue) {
                if(virtue !== '_id' && virtue !== '__v' && virtue !== 'user'){
                    if(uservirtue[virtue] === 'debil'){
                        weakVirtuesArray.push(virtue)
                    }
                    if(uservirtue[virtue] === 'media'){
                        mediumVirtuesArray.push(virtue)
                    }
                    if(uservirtue[virtue] === 'fuerte'){
                        hardVirtuesArray.push(virtue)
                    }
                }
            }

            setWeakVirtues(weakVirtuesArray)
            setMediumVirtues(mediumVirtuesArray)
            setHardVirtues(hardVirtuesArray)
        }
    }, [uservirtue])

    useEffect(() => {
        if(exercises){
            saveExercises()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exercises])

    const saveExercises = async() => {
        let allVirtuesExercisesCopy = {
            'aceptacion': [],
            'admiracion': [],
            'alegria': [],
            'compania': [],
            'compasion': [],
            'dignidad': [],
            'disciplina': [],
            'educacion': [],
            'generosidad': [],
            'guia': [],
            'humildad': [],
            'identidad': [],
            'inspiracion': [],
            'justicia': [],
            'lealtad': [],
            'libertad': [],
            'paciencia': [],
            'perdon': [],
            'perseverancia': [],
            'proteccion': [],
            'responsabilidad': [],
            'sabiduria': [],
            'valentia': [],
        }

        let dateMax = null
        Promise.all(await exercises?.map(async(exercise) => {
            if(dateMax === null || dateMax < new Date(exercise.date)){
                dateMax = new Date(exercise.date).getTime()
            }
            if(exercise.done !== true){
                if(allVirtuesExercisesCopy[exercise.virtue]){
                    if(!allVirtuesExercisesCopy[exercise.virtue]?.find(exercisesFind => exercisesFind._id === exercise._id)){
                        allVirtuesExercisesCopy = {...allVirtuesExercisesCopy, [exercise.virtue]: [...allVirtuesExercisesCopy[exercise.virtue], exercise]}
                    }
                }else{
                    allVirtuesExercisesCopy = {...allVirtuesExercisesCopy, [exercise.virtue]: [exercise]}
                }
            }

            return true
        })).then(
            setAllVirtuesExercises(allVirtuesExercisesCopy)
        )
        setLastUpdate(dateMax)
    }

    useEffect(() => {
        if(tests){
            let arrayweakVirtuesCrisis = []
            let arrayweakVirtuesChallenge = []
            let arrayweakVirtuesFlow = []
            let arrayweakVirtuesWow = []

            let arraymediumVirtuesCrisis = []
            let arraymediumVirtuesChallenge = []
            let arraymediumVirtuesFlow = []
            let arraymediumVirtuesWow = []

            let arrayhardVirtuesCrisis = []
            let arrayhardVirtuesChallenge = []
            let arrayhardVirtuesFlow = []
            let arrayhardVirtuesWow = []
            
            tests?.map((test, itest) => {
                if(itest === 0){
                    weakVirtues?.map((virtue) => {
                        if(test[virtue] === 'diario'){
                            arrayweakVirtuesCrisis.push(virtue)
                        }
                        if(test[virtue] === 'algunos'){
                            arrayweakVirtuesChallenge.push(virtue)
                        }
                        if(test[virtue] === 'pocos'){
                            arrayweakVirtuesFlow.push(virtue)
                        }
                        if(test[virtue] === 'ninguno'){
                            arrayweakVirtuesWow.push(virtue)
                        }
    
                        return true
                    })
    
                    mediumVirtues?.map((virtue) => {
                        if(test[virtue] === 'diario'){
                            arraymediumVirtuesCrisis.push(virtue)
                        }
                        if(test[virtue] === 'algunos'){
                            arraymediumVirtuesChallenge.push(virtue)
                        }
                        if(test[virtue] === 'pocos'){
                            arraymediumVirtuesFlow.push(virtue)
                        }
                        if(test[virtue] === 'ninguno'){
                            arraymediumVirtuesWow.push(virtue)
                        }
    
                        return true
                    })
    
                    hardVirtues?.map((virtue) => {
                        if(test[virtue] === 'diario'){
                            arrayhardVirtuesCrisis.push(virtue)
                        }
                        if(test[virtue] === 'algunos'){
                            arrayhardVirtuesChallenge.push(virtue)
                        }
                        if(test[virtue] === 'pocos'){
                            arrayhardVirtuesFlow.push(virtue)
                        }
                        if(test[virtue] === 'ninguno'){
                            arrayhardVirtuesWow.push(virtue)
                        }
    
                        return true
                    })
                }

                return true
            })
            setAllVirtuesWeak([
                arrayweakVirtuesCrisis,
                arrayweakVirtuesChallenge,
                arrayweakVirtuesFlow,
                arrayweakVirtuesWow,
            ])
            setAllVirtuesMedium([
                arraymediumVirtuesCrisis,
                arraymediumVirtuesChallenge,
                arraymediumVirtuesFlow,
                arraymediumVirtuesWow,
            ])
            setAllVirtuesHard([
                arrayhardVirtuesCrisis,
                arrayhardVirtuesChallenge,
                arrayhardVirtuesFlow,
                arrayhardVirtuesWow
            ])
            setAllVirtuesState([
                arrayweakVirtuesCrisis,
                arrayweakVirtuesChallenge,
                arrayweakVirtuesFlow,
                arrayweakVirtuesWow,
                arraymediumVirtuesCrisis,
                arraymediumVirtuesChallenge,
                arraymediumVirtuesFlow,
                arraymediumVirtuesWow,
                arrayhardVirtuesCrisis,
                arrayhardVirtuesChallenge,
                arrayhardVirtuesFlow,
                arrayhardVirtuesWow
            ])
        }
    }, [tests, weakVirtues, mediumVirtues, hardVirtues])

    const addExercise = (e, virtue) =>{
        e.preventDefault()

        let description = document.getElementById('description-'+virtue).value
        let level = document.getElementById('level-'+virtue).value
        let frequency = document.getElementById('frequency-'+virtue).value

        if(description !== ''){
            dispatch(sendTrainingTest(client.id, description, level, frequency, virtue))
            Swal.fire(t('training.added'), '', 'success')
        }else{
            Swal.fire(t('training.nodescription'), '', 'warning')
        }

        document.getElementById('description-'+virtue).value = ''
        document.getElementById('level-'+virtue).value = 'low'
        document.getElementById('frequency-'+virtue).value = 'daily'
    }

    const changeExercise = (e, exercise) =>{
        e.preventDefault()

        let level = document.getElementById('level-'+exercise).value
        let frequency = document.getElementById('frequency-'+exercise).value

        dispatch(editTrainingTest(exercise, level, frequency))
    }

    const deleteExercise = (e, exercise) =>{
        e.preventDefault()

        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('training.letsgo'),
            denyButtonText: t('training.cancel'),
            icon: 'warning',
            text: t('training.deletetraining')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setdoneTrainingTest(exercise, false))
                Swal.fire(t('training.deletessuccess'), '', 'success')
            }
        })
    }

    const doneExercise = (e, exercise) =>{
        e.preventDefault()

        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('training.letsgo'),
            denyButtonText: t('training.cancel'),
            icon: 'warning',
            text: t('training.endtraining')
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    showConfirmButton: true,
                    showDenyButton: true,
                    confirmButtonText: t('training.yes'),
                    denyButtonText: t('training.no'),
                    icon: 'warning',
                    text: t('training.savetraining')
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(setdoneTrainingTest(exercise, true))
                    }else{
                        dispatch(setdoneTrainingTest(exercise, false))
                    }
                    fire(0.25, {
                        spread: 26,
                        startVelocity: 55,
                    });
                    fire(0.2, {
                        spread: 60,
                    });
                    fire(0.35, {
                        spread: 100,
                        decay: 0.91,
                        scalar: 0.8
                    });
                    fire(0.1, {
                        spread: 120,
                        startVelocity: 25,
                        decay: 0.92,
                        scalar: 1.2
                    });
                    fire(0.1, {
                        spread: 120,
                        startVelocity: 45,
                    });
                    Swal.fire(t('training.savessuccess'), '', 'success')
                })
            }
        })
    }

    const fire = (particleRatio, opts) => {
        confetti(Object.assign({},  {origin: { y: 0.7 }}, opts, {
            particleCount: Math.floor(200 * particleRatio)
        }));
    }

    return (
        <>
            <Container fluid className='contenido modulo2'>
                <Row className='px-0 px-md-5'>
                    <Col xs="12" className='mx-auto'>
                        <div className="form p-2 p-md-4">
                            <div>
                                <Row>
                                    <Col className='px-4 d-flex'>
                                        <h2 className='verde'>{t('training.title')}</h2>
                                        <Link to="/client/profile" className='position-fixed icono-home'>
                                            <i class="fas fa-home fa-2x"></i>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='px-4 pb-3'>
                                        <div className="alert auto alert-success text-center" role="alert">
                                            <h3 className='m-0 text-white'>{t('training.alert')}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='px-4'>
                                        <p><strong>{t('training.paragraph1')}</strong></p>
                                        <p>
                                            {t('training.paragraph2')}
                                            {lastUpdate !== null
                                                ?
                                                    <>
                                                        &nbsp;&nbsp;&nbsp;{ new Date(lastUpdate).getHours()}:{ new Date(lastUpdate).getMinutes()<10 ? '0'+new Date(lastUpdate).getMinutes() : new Date(lastUpdate).getMinutes()}:{ new Date(lastUpdate).getSeconds()<10 ? '0'+new Date(lastUpdate).getSeconds() : new Date(lastUpdate).getSeconds()}
                                                        &nbsp;&nbsp;&nbsp;{ new Date(lastUpdate).getDate()<10 ? '0'+new Date(lastUpdate).getDate() : new Date(lastUpdate).getDate()}/{ new Date(lastUpdate).getMonth()+1<9 ? '0'+parseInt(new Date(lastUpdate).getMonth()+1) : parseInt(new Date(lastUpdate).getMonth()+1)}/{ new Date(lastUpdate).getFullYear()}
                                                    </>
                                                :
                                                    <></>
                                            }
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                            {[allVirtuesWeak,allVirtuesMedium,allVirtuesHard].map((bloque,key) => (
                                 <Card className='my-5 pt-4 pb-2 px-2 px-md-5' key={key}>
                                    <Card.Body>
                                        {bloque?.map((typevirtue, itypevirtue) => (
                                                <Row className='' key={itypevirtue}>
                                                    <Col>
                                                        {itypevirtue === 0
                                                            ?                                                    
                                                                <Col xs={12} md={6}>
                                                                    <div className="alert alert-success auto d-flex align-items-center justify-content-between" role="alert">
                                                                        {key === 0
                                                                            ?
                                                                                <>
                                                                                    <h3 className='m-0 text-white'>{t('virtuestype.weak')}</h3>
                                                                                    <PDFDownloadLink document={<Report type='weak' typevirtuesdata={[allVirtuesState[0],allVirtuesState[1],allVirtuesState[2],allVirtuesState[3]]} virtuesdata={allVirtuesExercises}/>} fileName={'exercises_motif.pdf'}>
                                                                                        <Button><i className="fa fa-download"></i></Button>
                                                                                    </PDFDownloadLink>
                                                                                </>
                                                                            :
                                                                                <></>
                                                                        }
                                                                        {key === 1
                                                                            ?
                                                                                <>
                                                                                    <h3 className='m-0 text-white'>{t('virtuestype.medium')}</h3>
                                                                                    <PDFDownloadLink document={<Report type='medium' typevirtuesdata={[allVirtuesState[4],allVirtuesState[5],allVirtuesState[6],allVirtuesState[7]]} virtuesdata={allVirtuesExercises}/>} fileName={'exercises_motif.pdf'}>
                                                                                        <Button><i className="fa fa-download"></i></Button>
                                                                                    </PDFDownloadLink>
                                                                                </>
                                                                            :
                                                                                <></>
                                                                        }
                                                                        {key === 2
                                                                            ?
                                                                                <>
                                                                                    <h3 className='m-0 text-white'>{t('virtuestype.hard')}</h3>
                                                                                    <PDFDownloadLink document={<Report type='hard' typevirtuesdata={[allVirtuesState[8],allVirtuesState[9],allVirtuesState[10],allVirtuesState[11]]} virtuesdata={allVirtuesExercises}/>} fileName={'exercises_motif.pdf'}>
                                                                                        <Button><i className="fa fa-download"></i></Button>
                                                                                    </PDFDownloadLink>
                                                                                </>
                                                                            :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            :
                                                                <></>
                                                        }
                                                        {typevirtue.length > 0 && typevirtue?.map((virtue, ivirtue) => (
                                                            <Fragment key={ivirtue}>
                                                                <Row>
                                                                    <Col className='d-flex align-items-center'>
                                                                        <OverlayTrigger trigger="click" placement="right"
                                                                            overlay={
                                                                                <Popover>
                                                                                    <Popover.Header as="h3">{t('virtuetraining.comoentrenar')} {t('virtues.'+virtue)}</Popover.Header>
                                                                                    <Popover.Body>{t('virtuetraining.'+virtue)}</Popover.Body>
                                                                                </Popover>
                                                                            }>
                                                                            <button type="button" className="alert alert-info mt-3 me-3 popovers d-flex justify-content-between align-items-center popover-training" data-toggle="popover">
                                                                                {t('virtues.'+virtue)}
                                                                                <i className='fas fa-plus ms-5'></i>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                        {itypevirtue === 0 || itypevirtue === 4 || itypevirtue === 8 ? <div className='bola-diario bola-frecuencia bola-mx-auto'></div> : <></>}
                                                                        {itypevirtue === 1 || itypevirtue === 5 || itypevirtue === 9 ? <div className='bola-algunos bola-frecuencia bola-mx-auto'></div> : <></>}
                                                                        {itypevirtue === 2 || itypevirtue === 6 || itypevirtue === 10 ? <div className='bola-pocos bola-frecuencia bola-mx-auto'></div> : <></>}
                                                                        {itypevirtue === 3 || itypevirtue === 7 || itypevirtue === 11 ? <div className='bola-ninguno bola-frecuencia bola-mx-auto'></div> : <></>}
                                                                    </Col>
                                                                </Row>
                                                                <Row className='pb-3 d-none d-md-flex'>
                                                                    <Col xs={12} md={4} className='text-center'>{t('training.exercise')}</Col>
                                                                    <Col xs={12} md={2} className='text-center'>{t('training.level')}</Col>
                                                                    <Col xs={12} md={2} className='text-center'>{t('training.frequency')}</Col>
                                                                    <Col xs={12} md={4} className='text-center'>{t('training.ended')}</Col>
                                                                </Row>
                                                                {allVirtuesExercises[virtue]?.length > 0 && allVirtuesExercises[virtue]?.map((exercise, iexercise) => (
                                                                    <Row key={iexercise} className='py-4 border rounded my-2'>
                                                                        <Col xs={12} md={4} className='d-flex align-items-center justify-content-center py-3'>
                                                                            <p className='text-uppercase'><strong>{exercise.description}</strong></p>
                                                                        </Col>
                                                                        <Col xs={12} md={2} className='text-center d-block d-md-flex align-items-center py-3'>
                                                                            <p className='d-block d-md-none'>{t('training.level')}</p>
                                                                            <Form.Control
                                                                                as='select'
                                                                                className='form-select'
                                                                                id={`level-${exercise._id}`}
                                                                                name={`level-${exercise._id}`}
                                                                                defaultValue={exercise.level}
                                                                                onChange={(e) => changeExercise(e, exercise._id)}
                                                                            >                                                                                        
                                                                                <option value='low'>{t('training.low')}</option>
                                                                                <option value='medium'>{t('training.medium')}</option>
                                                                                <option value='high'>{t('training.high')}</option>
                                                                            </Form.Control>
                                                                        </Col>
                                                                        <Col xs={12} md={2} className='text-center d-block d-md-flex align-items-center py-3'>
                                                                            <p className='d-block d-md-none'>{t('training.frequency')}</p>
                                                                            <Form.Control
                                                                                as='select'
                                                                                className='form-select'
                                                                                id={`frequency-${exercise._id}`}
                                                                                name={`frequency-${exercise._id}`}
                                                                                defaultValue={exercise.frequency}
                                                                                onChange={(e) => changeExercise(e, exercise._id)}
                                                                            >
                                                                                <option value='daily'>{t('training.daily')}</option>
                                                                                <option value='weekly'>{t('training.weekly')}</option>
                                                                                <option value='monthly'>{t('training.monthly')}</option>
                                                                                <option value='quarterly'>{t('training.quarterly')}</option>
                                                                                <option value='annually'>{t('training.annually')}</option>
                                                                                <option value='oncelive'>{t('training.oncelive')}</option>
                                                                            </Form.Control>
                                                                        </Col>
                                                                        <Col xs={12} md={4} className='d-flex align-items-center justify-content-around py-3'>
                                                                            <add-to-calendar-button
                                                                                name={t('training.reminder')}
                                                                                description={exercise.description}
                                                                                startDate={today}
                                                                                options="['Apple','Google','iCal','Microsoft365','MicrosoftTeams','Outlook.com','Yahoo']"
                                                                                trigger="click"
                                                                                listStyle="modal"
                                                                                iCalFileName="Reminder-Event"
                                                                                className="mx-1"
                                                                            >
                                                                            </add-to-calendar-button>                                                                        
                                                                            <Button className='btn-done' onClick={(e) => doneExercise(e, exercise._id)}><i className='fas fa-check'></i></Button>
                                                                        
                                                                            <Button className='btn-delete' onClick={(e) => deleteExercise(e, exercise._id)}><i className='fas fa-trash'></i></Button>
                                                                        </Col>
                                                                    </Row>
                                                                ))}
                                                                <Row className='mt-4'>
                                                                    <Col><p><strong>{t('training.newexercise')} {t('training.of')} {t('virtues.'+virtue)}</strong></p></Col>
                                                                </Row>
                                                                <Row className='mt-0 mb-4 border-bottom pb-4'>
                                                                    <Col xs={12} md={4} className='d-flex align-items-center justify-content-center py-3'>
                                                                        
                                                                        <Form.Control
                                                                            type="text"                                                                                
                                                                            id={`description-${virtue}`}
                                                                            name={`description-${virtue}`}
                                                                            placeholder={t('training.description')}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={12} md={2} className='d-flex align-items-center justify-content-center py-3'>
                                                                        <Form.Control
                                                                            as='select'
                                                                            className='form-select form-control-verde'
                                                                            id={`level-${virtue}`}
                                                                            name={`level-${virtue}`}
                                                                        >                                                                                
                                                                            <option value='low' defaultValue>{t('training.low')}</option>
                                                                            <option value='medium'>{t('training.medium')}</option>
                                                                            <option value='high'>{t('training.high')}</option>
                                                                        </Form.Control>
                                                                    </Col>
                                                                    <Col xs={12} md={2} className='d-flex align-items-center justify-content-center py-3'>
                                                                        <Form.Control
                                                                            as='select'
                                                                            className='form-select form-control-verde'
                                                                            id={`frequency-${virtue}`}
                                                                            name={`frequency-${virtue}`}
                                                                        >
                                                                            <option value='daily' defaultValue>{t('training.daily')}</option>
                                                                            <option value='weekly'>{t('training.weekly')}</option>
                                                                            <option value='monthly'>{t('training.monthly')}</option>
                                                                            <option value='quarterly'>{t('training.quarterly')}</option>
                                                                            <option value='annually'>{t('training.annually')}</option>
                                                                            <option value='oncelive'>{t('training.oncelive')}</option>
                                                                        </Form.Control>
                                                                    </Col>
                                                                    <Col xs={12} md={4} className='d-flex align-items-center py-3'>
                                                                        <Button onClick={(e) => addExercise(e, virtue)}>{t('training.add')} <i className='fas fa-plus mx-auto'></i></Button>
                                                                    </Col>
                                                                </Row>                                                                
                                                            </Fragment>
                                                        ))}
                                                    </Col>
                                                </Row>
                                        ))}
                                    </Card.Body>
                                </Card> 
                            ))}
                                                      
                            <Row xs="12" id="evaluation_test_footer_right">
                                <Col className="text-end mt-2 mt-md-0">
                                    <Link to="/client/test/responsibility/essential">
                                        <Button variant='primary' type="button">
                                        {t('responsibility.continue')}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                            <Row className='my-4 botones-pasos'>
                                <Col className="d-flex justify-content-md-between justify-content-center flex-wrap">

                                            <Link to="/client/test/responsibility/weeklyresult">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.weekly')}
                                                </Button>
                                            </Link>

                                            <Link to="/client/test/responsibility/evaluation">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.evaluation')}
                                                </Button>
                                            </Link>

                                            <Button className='boton-blanco' variant='primary' type="submit">
                                                {t('responsibility.training')}
                                            </Button>
                                        
                                            <Link to="/client/test/responsibility/historical">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.historical')}
                                                </Button>
                                            </Link>

                                            <Link to="/client/test/responsibility/essential">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.essential')}
                                                </Button>
                                            </Link>
                                            
                                </Col>
                            </Row>
                            <Row className='my-4 botones-pasos'>
                                <Col className="d-flex justify-content-center flex-wrap">
                                    <Link to="/client/test/responsibility/guide">
                                        <Button variant='primary guide' type="submit" disabled>
                                            {t('responsibility.guide')}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>                            
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}