import React from 'react'
import {Container,Row,Col,Nav} from 'react-bootstrap'
import ChangeLanguage from './translations/ChangeLanguage'

export const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col xs="12" sm="3" className='m-3 m-sm-0'>
            <img
              src="/images/logotipo-invertido.png"
              width="120"                
              className="d-inline-block align-top"
              alt="Logotipo Motif"              
            />
          </Col>
          <Col xs="12" sm="9" className='text-white'>
            <Nav
              activeKey="/home"
              onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}             
              className="justify-sm-content-end"
            >
              <Nav.Item>
                <Nav.Link href="/home">Nosotros</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-1">El Método Motif</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-2">Evalua tu bienestar</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-3">Itinerario de desarrollo</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-4">Contacto</Nav.Link>
              </Nav.Item>  
              <ChangeLanguage/>            
            </Nav>
          </Col>
        </Row>
        <Row className='my-4'>
          <Col>
            <hr className='text-white'/>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{(new Date().getFullYear())} &copy; Grupo Axius</p>
          </Col>
          <Col className="d-flex justify-content-md-end justify-content-center">
            <img
                  src="/images/logos-espana.png"
                  width="400"
                  className="bg-white"
                  alt=""
            />
          </Col>
        </Row>        
      </Container>
    </footer>
  )
}
