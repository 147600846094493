import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Container, Row, Col, Form, Button, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { addreview, getGuide } from '../../../../actions/guideActions'
import { Link, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function Explain() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()
	const {virtue} = useParams()
    const {guide} = useSelector(state=>state.guide)
    const language = localStorage.getItem('motif-lang') ? localStorage.getItem('motif-lang') : (navigator.language || navigator.userAgent.userLanguage).split('-')[0]

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getGuide(virtue))
    }, [dispatch, virtue])

    const sendComment = (e) =>{
        e.preventDefault()
        let comment = document.getElementById('comment').value

        dispatch(addreview(client.id, guide._id, comment))

        Swal.fire({
            showConfirmButton: true,
            confirmButtonText: t('guide.great'),
            icon: 'success',
            text: t('guide.waitforaproved')
        })
        
        document.getElementById('comment').value = ''
    }

    return (
        <>
            <Container fluid className='contenido modulo2'>
                <Row className='px-0 px-md-5'>
                    <Col xs="12" className='mx-auto'>
                        <div className="form p-2 p-md-4">
                            <div>
                                <Row>
                                    <Col className='px-4 d-flex'>
                                        <h2 className='verde'>{t('guide.title')}</h2>
                                        <Link to="/client/test/responsibility/guide" className='position-fixed icono-home'>
                                            <i class="fas fa-home fa-2x"></i>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="auto" className='px-4 pb-3'>
                                        <div className="alert auto alert-success text-center" role="alert">
                                            <h3 className='m-0 text-white'>{t('guide.guides')}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='px-4'>
                                        <p><strong>{t('guide.paragraph1')}</strong></p>
                                    </Col>
                                </Row>
                            </div>
                            <Card>
                                <Card.Body className='px-4 px-md-5'>
                                    <div className='pt-3 pb-4'>
                                        {guide?.virtue
                                            ?
                                                <div className="alert auto alert-success text-center me-auto" role="alert">
                                                    <h3 className='m-0 text-white'>{t('virtues.'+guide.virtue)}</h3>
                                                </div>
                                            :
                                                <></>
                                        }
                                        {guide?.url
                                            ?
                                                <div className='contenedor-video'>
                                                    <div id='video'>
                                                        <iframe src={`https://www.youtube.com/embed/${guide.url}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                                    </div>
                                                </div>
                                            :
                                                <></>
                                        }
                                        {language === 'es'
                                            ?
                                                <>
                                                    {guide?.text1
                                                        ?
                                                            <>
                                                                <p className='texto-verde'><strong>{t('guide.text1')}</strong></p>                                                                
                                                                <p dangerouslySetInnerHTML={{__html: guide.text1}}></p>
                                                            </>
                                                        :
                                                            <></>
                                                    }
                                                    {guide?.text2
                                                        ?
                                                            <>
                                                                <p className='texto-verde'><strong>{t('guide.text2')}</strong></p>                                                                
                                                                <p dangerouslySetInnerHTML={{__html: guide.text2}}></p>
                                                            </>
                                                        :
                                                            <></>
                                                    }
                                                </>
                                            :
                                                <>
                                                    {guide?.text3
                                                        ?
                                                            <>
                                                                <p className='texto-verde'><strong>{t('guide.text1')}</strong></p>
                                                                <p dangerouslySetInnerHTML={{__html: guide.text3}}></p>
                                                            </>
                                                        :
                                                            <></>
                                                    }
                                                    {guide?.text4
                                                        ?
                                                            <>
                                                                <p className='texto-verde'><strong>{t('guide.text2')}</strong></p>
                                                                <p dangerouslySetInnerHTML={{__html: guide.text4}}></p>
                                                            </>
                                                        :
                                                            <></>
                                                    }
                                                </>
                                        }
                                        <div className='pt-5 pb-4'>
                                            <div className='py-2'>
                                                {t('guide.comments')}
                                            </div>
                                            <div className='py-2'>
                                                {guide?.comments?.length > 0
                                                    ?
                                                        guide?.comments.map((comment, icomments) => (
                                                            comment.aproved === true
                                                                ?
                                                                    <Fragment key={icomments}>
                                                                        <p className='texto-verde'>{comment.user.username} - { new Date(comment.date).getHours()}:{ new Date(comment.date).getMinutes()<10 ? '0'+new Date(comment.date).getMinutes() : new Date(comment.date).getMinutes()}:{ new Date(comment.date).getSeconds()<10 ? '0'+new Date(comment.date).getSeconds() : new Date(comment.date).getSeconds()}
                                                                        &nbsp;&nbsp;{ new Date(comment.date).getDate()<10 ? '0'+new Date(comment.date).getDate() : new Date(comment.date).getDate()}/{ new Date(comment.date).getMonth()+1<9 ? '0'+parseInt(new Date(comment.date).getMonth()+1) : parseInt(new Date(comment.date).getMonth()+1)}/{ new Date(comment.date).getFullYear()}</p>
                                                                        <p>{comment.comment}</p>
                                                                    </Fragment>
                                                                :
                                                                    <Fragment key={icomments}></Fragment>
                                                        ))
                                                    :
                                                        <>{t('guide.thereisntcomments')}</>
                                                }
                                            </div>
                                            <Form.Group>
                                                <Form.Label className="form-label mt-3">{t('guide.comment')}</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    className='form-control form-control-verde'
                                                    rows={6}
                                                    id='comment'
                                                    name='comment'
                                                    placeholder={t('guide.comment')}
                                                    required
                                                />
                                            </Form.Group>
                                            <Button className="mt-3" type="submit" onClick={(e) => sendComment(e)}>{t('guide.sendcomment')}</Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Row className='my-4 botones-pasos'>
                                <Col className="d-flex justify-content-md-between justify-content-center flex-wrap">
                                    
                                            <Link to="/client/test/responsibility/weeklyresult">
                                                <Button className='boton-gris' variant='primary' type="submit" disabled>
                                                    {t('responsibility.weekly')}
                                                </Button>
                                            </Link>
                                    
                                            <Link to="/client/test/responsibility/evaluation">
                                                <Button className='boton-gris' variant='primary' type="submit" disabled>
                                                    {t('responsibility.evaluation')}
                                                </Button>
                                            </Link>
                                    
                                            <Link to="/client/test/responsibility/training">
                                                <Button className='boton-gris' variant='primary' type="submit" disabled>
                                                    {t('responsibility.training')}
                                                </Button>
                                            </Link>

                                            <Button className='boton-gris' variant='primary' type="submit" disabled>
                                                {t('responsibility.historical')}
                                            </Button>
                                    
                                            <Link to="/client/test/responsibility/essential">
                                                <Button className='boton-gris' variant='primary' type="submit" disabled>
                                                    {t('responsibility.essential')}
                                                </Button>
                                            </Link>
                                </Col>
                            </Row>   
                            <Row className='my-4 botones-pasos'>
                                <Col className="d-flex justify-content-center flex-wrap">
                                    <Link to="/client/test/responsibility/guide">
                                        <Button variant='primary guide' type="submit" disabled>
                                            {t('responsibility.guide')}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>                            
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}