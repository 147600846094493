import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Container,Row,Col,Form,Button,Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { getWeakTest, sendWeakTest } from '../../../../actions/weakTestActions'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Virtues from './Virtues'

export default function Weak() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()
    const navigate = useNavigate()

    const weak = [  'aceptacion','admiracion','alegria','compania',
                    'compasion','dignidad','disciplina','educacion',
                    'generosidad','guia','humildad','identidad',
                    'inspiracion','justicia','lealtad','libertad',
                    'paciencia','perdon','perseverancia','proteccion',
                    'responsabilidad','sabiduria','valentia'
                ]
    const maximoElementos = 8
    const {test} = useSelector(state=>state.weakTest)
    // const [virtuesWeak, setVirtuesWeak] = useState([])

    let questions = []
    for(let i=0; i<=22; i++){
        questions.push(i)
    }
    const [checkedState, setCheckedState] = useState(
        new Array(questions.length).fill(false)
    )
    const [totalElementos, setTotalElementos] = useState(0)
    const handleOnChange = (position) => {         
        let updatedCheckedState = []
        checkedState.forEach((item, index) => {
            if (index === position) {
                updatedCheckedState.push(!item);
            } else {
                updatedCheckedState.push(item);
            }
        })
        if(totalElementos<=maximoElementos-1){
            setCheckedState(updatedCheckedState);
            const total = updatedCheckedState.reduce(
                (sum, currentState, index) => {
                    if (currentState === true) {
                    return sum + 1
                    }
                    return sum
                },
                0
                )
            setTotalElementos(total)
        }else{
            if(!checkedState[position]){
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'error',
                    text: t('weak.limit')
                })
            }else{
                setCheckedState(updatedCheckedState);
                setTotalElementos(totalElementos-1)
            }
        }
    }
    const handleClick = async(e) => {
        e.preventDefault()

        let respuestas = []
        checkedState.map( (currentState,index) => {
            if(currentState){
                respuestas.push({number:index+1, weak: weak[index]})
            }
            return true
        })
        dispatch(sendWeakTest(respuestas,client.id))
        navigate('/client/test/conscience/prospect')
    }

    useEffect(() => {
        if(test){
            let array_virtues = []

            test.responses?.map((response)=>{
                array_virtues.push(response.weak)
                return true
            })

            let array_count = []
            array_virtues.forEach(function (virtue) {
                if (array_count.find(ele => ele.virtue === virtue)) {
                    array_count.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count.push({virtue: virtue, count: 1})
                }
            })
            array_count.sort((itemA, itemB) => itemB.count - itemA.count)
            // setVirtuesWeak(array_count)
        }
    }, [test])
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client?.id){
            dispatch(getWeakTest(client.id))
        }
    }, [client, dispatch, t])
    
    useEffect(() => {
        if(test !== null){
            if(test.responses?.length > 0){
                test.responses.forEach(response => {
                    checkedState[response.number-1] = true
                    setTotalElementos(totalElementos+1)
                })
                setCheckedState(checkedState)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [test])
    
    return (
    <>
        <Container fluid className='contenido modulo1'>
            <Row className='px-1 px-md-5'>
                <Col xs="12" className='mx-auto'>
                    <div className="form p-md-4 p-0">                        
                        <div>
                            <Row>
                                <Col className='px-4 d-flex'>
                                    <h2>{t('weak.title')}</h2>                                
                                    <Link to="/client/profile" className='position-fixed icono-home'>
                                        <i class="fas fa-home fa-2x"></i>
                                    </Link>
								</Col>
                            </Row>                         
                            <Row>
                                <Col className='px-4 pb-3'>
                                    <div className="alert auto alert-success text-center" role="alert">
                                        <h3 className='m-0 text-white'>{t('weak.alert')}</h3>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='px-4'>
                                    <p><strong>{t('weak.paragraph1')}</strong></p>
                                    <p><strong>{t('weak.paragraph2')}</strong></p>
                                </Col>
                            </Row>
                            <div className='d-flex justify-content-start my-5 px-4'>
                                <div>
                                    <img
                                    src={`/images/icono-mano-check.png`}
                                    width={50}
                                    alt=""
                                    />
                                </div>
                                <div className='ps-5'>
                                    <p className='verde'><strong>{t('weak.title-questions')}</strong></p>
                                    <p><strong>{t('weak.explanation')}</strong></p>
                                </div>
                            </div>
                        </div>
                        <Card>
                            <Card.Body className='px-5'>                 
                                {/* RECORREMOS TODAS LAS PREGUNTAS  */}
                                {questions.map((question) => (
                                    <div key={`${question}`} attr_key={`${question}`} className='question-conscience'>
                                    <Row className='pt-5 pb-4'>
                                        <Col xs="9" md="8">
                                            <p className='virtudes-entrenar-title-question-noblocked'><strong>{t('weak.question'+(question+1))}</strong></p>
                                        </Col>
                                        <Col xs="3" md="4" className='d-flex justify-content-center align-items-center'>
                                            <Form.Check 
                                                type="checkbox"
                                                name={`q${question+1}`}
                                                checked={checkedState[question]}
                                                onChange={() => handleOnChange(question)}
                                                disabled={!client?.active_test}
                                            />
                                        </Col>
                                    </Row>
                                    </div>
                                ))}
                            </Card.Body>
                         </Card>                       
                        {/* PIE Y BOTON */}
                        <Row className='my-4 botones-pasos'>
                            <Col className="d-flex justify-content-md-between justify-content-center flex-wrap">
                                        <Link to="/client/result">
                                            <Button className='boton-gris' variant='primary' type="submit">
                                                {t('conscience.initial')}
                                            </Button>
                                        </Link>
                                    
                                        <Button className='boton-blanco' variant='primary' type="submit">
                                            {t('conscience.virtues-to-train')}
                                        </Button>
                                    
                                        <Link to="/client/test/conscience/prospect">
                                            <Button className='boton-gris' variant='primary' type="submit">
                                                {t('conscience.limiting-expectations')}
                                            </Button>
                                        </Link>
                                    
                                        <Link to="/client/test/conscience/mediumhard">
                                            <Button className='boton-gris' variant='primary' type="submit">
                                                {t('conscience.medium-strong-virtues')}
                                            </Button>
                                        </Link>
                                    
                                        <Button variant='primary' type="submit" onClick={handleClick}>
                                            <i className='fas fa-save'></i> {t('global.saveandgo')}
                                        </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Virtues></Virtues>
            </Row>
        </Container>
    </>
    )
}