import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Form, Button, Card, Accordion, Table, Modal } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import Select from "react-select"
import useAuth from '../../../../context/auth/useAuth'
import { getProspectTest, sendProspectTest, removeProspectTest } from '../../../../actions/prospectTestActions'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import emotionsJSON from "../conscience/virtues/emotions.json"
import behaviorsJSON from "../conscience/virtues/behaviors.json"
import Virtues from './Virtues'


export default function Prospect() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()
    const [modalShow, setModalShow] = useState(false)
    const [modalShowTwo, setModalShowTwo] = useState(false)

    const {tests} = useSelector(state=>state.prospectTest)

    const [feelingDescription, setFeelingDescription] = useState([])
    const [conductDescription, setConductDescription] = useState([])

    const [who1, setWho1] = useState(null)
    const [prospect1, setProspect1] = useState('')
    const [feelings1, setFeelings1] = useState([])
    const [conducts1, setConducts1] = useState([])

    const [virtues1, setVirtues1] = useState([])

    const [who2, setWho2] = useState(null)
    const [prospect2, setProspect2] = useState('')
    const [feelings2, setFeelings2] = useState([])
    const [conducts2, setConducts2] = useState([])

    const [virtues2, setVirtues2] = useState([])

    const emotions = []
    for (let indice in emotionsJSON) {
        emotions.push(indice)
    }

    const behaviors = []
    for (let indice in behaviorsJSON) {
        behaviors.push(indice)
    }
    
    const persons = ["yo", "padre", "madre", "abuelo", "abuela", "hermano", "hermana",
        "tio", "tia", "primo", "prima", "amigo", "amiga", "pareja", "expareja", "hijo",
        "hija", "colaborador", "socio", "socia", "cliente", "lagente", "otro"
    ]

    const [personsOptions, setPersonsOptions] = useState([])
    const [emotionsOptions, setEmotionsOptions] = useState([])
    const [behaviorsOptions, setBehaviorsOptions] = useState([])

    const selectChangeWho1 = (person) => { setWho1(person.value) }
    const selectChangeWho2 = (person) => { setWho2(person.value) }

    const selectChangeProspect1 = (prospect) => { setProspect1(prospect.target.value) }
    const selectChangeProspect2 = (prospect) => { setProspect2(prospect.target.value) }

    const selectChangeEmotions1 = (emotions) => {
        let arrayFeelings = []
        emotions.forEach(emotion => {
            arrayFeelings.push({emotion: emotion.value, virtue: emotionsJSON[emotion.value]})
        })
        setFeelings1(arrayFeelings)
        setEmotionsOptions({...emotionsOptions, selected1: emotions})
    }

    const selectChangeEmotions2 = (emotions) => {
        let arrayFeelings = []
        emotions.forEach(emotion => {
            arrayFeelings.push({emotion: emotion.value, virtue: emotionsJSON[emotion.value]})
        })
        setFeelings2(arrayFeelings)
        setEmotionsOptions({...emotionsOptions, selected2: emotions})
    }

    const selectChangeBehaviors1 = (behaviors) => {
        let arrayConducts = []
        behaviors.forEach(behavior => {
            arrayConducts.push({behavior: behavior.value, virtue: behaviorsJSON[behavior.value]})
        })
        setConducts1(arrayConducts)
        setBehaviorsOptions({...behaviorsOptions, selected1: behaviors})
    }

    const selectChangeBehaviors2 = (behaviors) => {
        let arrayConducts = []
        behaviors.forEach(behavior => {
            arrayConducts.push({behavior: behavior.value, virtue: behaviorsJSON[behavior.value]})
        })
        setConducts2(arrayConducts)
        setBehaviorsOptions({...behaviorsOptions, selected2: behaviors})
    }

    const handleSubmit1 = async(e) => {
        e.preventDefault()

        if(prospect1 !== null && feelings1.length > 0 && conducts1.length > 0){
            dispatch(sendProspectTest(client.id, true, who1, prospect1, feelings1, conducts1))
            setProspect1('')
            setFeelings1([])
            setEmotionsOptions({...emotionsOptions, selected1: []})
            setConducts1([])
            setBehaviorsOptions({...behaviorsOptions, selected1: []})
            Swal.fire({
                showConfirmButton: true,
                icon: 'success',
                text: t('prospect.added')
            })
        }else{
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text: t('prospect.addfail')
            })
        }
    }

    const handleSubmit2 = async(e) => {
        e.preventDefault()

        if(prospect2 !== null && feelings2.length > 0 && conducts2.length > 0){
            dispatch(sendProspectTest(client.id, false, who2, prospect2, feelings2, conducts2))
            setProspect2('')
            setFeelings2([])
            setEmotionsOptions({...emotionsOptions, selected2: []})
            setConducts2([])
            setBehaviorsOptions({...behaviorsOptions, selected2: []})
            Swal.fire({
                showConfirmButton: true,
                icon: 'success',
                text: t('prospect.added')
            })
        }else{
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text: t('prospect.addfail')
            })
        }
    }

    useEffect(() => {
        let arrayPersons = []
        let arrayPersonSelected1
        let arrayPersonSelected2
        persons?.forEach(person => {
            let object = {value: person, label: t('prospect.persons.'+person)}
            arrayPersons.push(object)
            if(who1 === person){
                arrayPersonSelected1 = object
            }
            if(who2 === person){
                arrayPersonSelected2 = object
            }
        })
        setPersonsOptions({options: arrayPersons, selected1: arrayPersonSelected1, selected2: arrayPersonSelected2})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [who1, who2])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client?.id){
            let arrayPersons = []
            let arrayPersonSelected1
            let arrayPersonSelected2
            persons?.forEach(person => {
                let object = {value: person, label: t('prospect.persons.'+person)}
                arrayPersons.push(object)
                if(arrayPersonSelected1 === undefined){
                    arrayPersonSelected1 = object
                    setWho1(object.value)
                }
                if(arrayPersonSelected2 === undefined){
                    arrayPersonSelected2 = object
                    setWho2(object.value)
                }
            })
            setPersonsOptions({options: arrayPersons, selected1: arrayPersonSelected1, selected2: arrayPersonSelected2})

            let arrayEmotions = []
            let arrayEmotionsDescriptions = []
            emotions?.forEach(emotion => {
                let object = {value: emotion, label: t('emotions.'+emotion)}
                arrayEmotions.push(object)
                arrayEmotionsDescriptions.push(t('emotions.'+emotion))
            })
            
            arrayEmotions.sort(function (a, b) {
                return a.label.localeCompare(b.label);
            })

            setEmotionsOptions({options: arrayEmotions, selected1: [], selected2: []})

            let arrayBehaviors = []
            let arrayConductsDescriptions = []
            behaviors?.forEach(behavior => {
                let object = {value: behavior, label: t('behaviors.'+behavior)}
                arrayBehaviors.push(object)
                arrayConductsDescriptions.push(t('behaviors.'+behavior))
            })
            
            arrayBehaviors.sort(function (a, b) {
                return a.label.localeCompare(b.label);
            })

            setBehaviorsOptions({options: arrayBehaviors, selected1: [], selected2: []})
            
            setFeelingDescription(arrayEmotionsDescriptions)
            setConductDescription(arrayConductsDescriptions)

            dispatch(getProspectTest(client?.id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, dispatch, t])

    const deleteProspectTest = (key) =>{
        dispatch(removeProspectTest(key))
        Swal.fire({
            showConfirmButton: true,
            icon: 'success',
            text: t('prospect.removed')
        })
    }

    useEffect(() => {
        if(tests){
            let array_virtues1 = []
            let array_virtues2 = []

            tests?.map((testrealizado, id)=>(
                testrealizado.me === true 
                ?
                    testrealizado?.feelings?.map((feeling)=>(
                        array_virtues1.push(feeling.virtue)
                    ))
                    &&
                    testrealizado?.conducts?.map((conduct)=>(
                        array_virtues1.push(conduct.virtue)
                    ))
                :
                    testrealizado?.feelings?.map((feeling)=>(
                        array_virtues2.push(feeling.virtue)
                    ))
                    &&
                    testrealizado?.conducts?.map((conduct)=>(
                        array_virtues2.push(conduct.virtue)
                    ))
            ))

            let array_count1 = []
            array_virtues1.forEach(function (virtue) {
                if (array_count1.find(ele => ele.virtue === virtue)) {
                    array_count1.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count1.push({virtue: virtue, count: 1})
                }
            })
            array_count1.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtues1(array_count1)

            let array_count2 = []
            array_virtues2.forEach(function (virtue) {
                if (array_count2.find(ele => ele.virtue === virtue)) {
                    array_count2.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count2.push({virtue: virtue, count: 1})
                }
            })
            array_count2.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtues2(array_count2)
        }
    }, [tests])

    return (
    <>
        <Container fluid className='contenido modulo1'>
            <Row className='px-1 px-md-5'>
                <Col xs="12" className='mx-auto'>
                    <div className="form p-md-4 p-0">                        
                        <Row>
                            <Col className='px-4 d-flex'>
                                <h2 className='verde'>{t('prospect.title')}</h2>
                                <Link to="/client/profile" className='position-fixed icono-home'>
                                    <i class="fas fa-home fa-2x"></i>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='px-4 pb-3'>
                                    <div className="alert auto alert-success text-center" role="alert">
                                        <h3 className='m-0 text-white'>{t('prospect.alert')}</h3>
                                    </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='px-4'>
                                <p><strong>{t('prospect.paragraph')}</strong></p>
                            </Col>
                        </Row>

                        {/* BLOQUE QUÉN TIENES LA EXPECTATIVA SOBRE TI */}
                        <Row className='py-5'>
                            <Col xs='12' md='6'>
                                <Card>
                                    <Card.Body className='px-3 px-md-5'>
                                        <Row className='py-3'>
                                            <Col>
                                                <div className="alert alert-default text-white" role="alert"><strong>{t('prospect.table-header1')}</strong></div>
                                                <Select className="form-control form-control-verde" 
                                                        name="who1" 
                                                        onChange={selectChangeWho1}
                                                        options={personsOptions.options}
                                                        value={personsOptions.selected1}
                                                        isDisabled={!client?.active_test}
                                                />                                                
                                            </Col>
                                        </Row>
                                        <Row className='py-3'>
                                            <Col>
                                                <p><strong className="verde">{t('prospect.table-header2')}</strong></p>
                                                <Form.Control
                                                        as="textarea"
                                                        className="form-control form-control-verde"
                                                        name='prospect1'
                                                        rows='5'
                                                        value={prospect1}
                                                        onChange={selectChangeProspect1} 
                                                        disabled={!client?.active_test}
                                                />  
                                            </Col>
                                        </Row>
                                        <Row className='py-3'>
                                            <Col>
                                                <p><strong className="verde">{t('prospect.table-header3a')}</strong> {t('prospect.table-header3b')}</p>
                                                <Select isMulti className="form-control form-control-verde" name="emotions1" onChange={selectChangeEmotions1} options={emotionsOptions.options} value={emotionsOptions.selected1} isDisabled={!client?.active_test}/>
                                                <br/>
                                                <Button variant="primary" onClick={() => setModalShow(true)}>
                                                    <i className="fas fa-search"></i> {t('prospect.feelings')}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className='py-3'>
                                            <Col>
                                                <p><strong className="verde">{t('prospect.table-header4a')}</strong> {t('prospect.table-header4b')}</p>
                                                <Select isMulti className="form-control form-control-verde select-multiple" name="behaviors1" onChange={selectChangeBehaviors1} options={behaviorsOptions.options} value={behaviorsOptions.selected1} isDisabled={!client?.active_test}/>
                                                <br/>
                                                <Button variant="primary" onClick={() => setModalShowTwo(true)}>
                                                    <i className="fas fa-search"></i> {t('prospect.conducts')}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className='py-3'>
                                            <Col xs="12">
                                                <div className="mt-2 mt-md-0 text-start">
                                                    <Button variant='primary' className='bg-modulo1' onClick={handleSubmit1}>
                                                        <span className="text-start">{t('prospect.add1')}</span> <span className="text-end">{t('prospect.add2')}</span>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs='12' md='6'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t('prospect.who')}</th>
                                            <th>{t('prospect.prospect')}</th>
                                            <th>{t('prospect.feelings')}</th>
                                            <th>{t('prospect.conducts')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tests?.map((testrealizado,id)=>(
                                            testrealizado.me === true 
                                            ?
                                                <tr key={id}>
                                                    <td>
                                                        <div className="form-label mx-2 btn btn-primary py-2 px-3" onClick={client?.active_test ? () => deleteProspectTest(testrealizado._id) : <></>}><i className="fas fa-times"></i></div>
                                                    </td>
                                                    <td>{t('prospect.persons.'+testrealizado.who)}</td>
                                                    <td>
                                                        {testrealizado.prospect[0]
                                                            ?
                                                            <>
                                                                {testrealizado.prospect[0].toUpperCase() + testrealizado.prospect?.slice(1).toLowerCase()}
                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                    </td>
                                                    <td>
                                                        {testrealizado?.feelings?.map((feeling,key)=>(
                                                            <div key={key}>
                                                                {t('emotions.'+feeling.emotion)}
                                                            </div>
                                                        ))}
                                                    </td>
                                                    <td>
                                                        {testrealizado?.conducts?.map((conduct, key)=>(
                                                            <div key={key}>
                                                                {t('behaviors.'+conduct.behavior)}                                                                
                                                            </div>
                                                        ))}
                                                    </td>
                                                </tr>
                                            :
                                                <Fragment key={id}></Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                                <h4 className='verde'>{t('resume.virtues-to-train')}</h4>
                                <Accordion> 
                                {virtues1?.map((virtue, id)=>{
                                    return (
                                        virtue.virtue !== ''
                                        ?
                                            <Fragment key={id}>
                                                <Accordion.Item eventKey={id} key={id} className='virtudes-sincolor'> 
                                                    <Accordion.Header>{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {t('descriptions.'+virtue.virtue )}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Fragment>
                                        :
                                        <></>
                                    )
                                })}
                                </Accordion>
                            </Col>
                        </Row>

                        {/* BLOQUE SOBRE QUÉN TIENES LA EXPECTATIVA */}
                        <Row>
                            <Col xs='12' md='6'>
                                <Card>
                                    <Card.Body className='px-3 px-md-5'>                                    
                                        <Row className='py-3'>
                                            <Col>
                                                <div className="alert alert-default text-white" role="alert"><strong>{t('prospect.table-header5')}</strong></div>                                                
                                                <Select className="form-control form-control-verde" 
                                                        name="who2"                                                 
                                                        onChange={selectChangeWho2} 
                                                        options={personsOptions.options} 
                                                        value={personsOptions.selected2}
                                                        isDisabled={!client?.active_test}
                                                />
                                            </Col>                                            
                                        </Row>
                                        <Row className='py-3'>
                                            <Col>
                                                <p><strong className="azul">{t('prospect.table-header2')}</strong></p>
                                                <Form.Group>
                                                    <Form.Control
                                                        as="textarea"
                                                        className='form-control form-control-verde'
                                                        name='prospect2'
                                                        value={prospect2}
                                                        onChange={selectChangeProspect2}
                                                        disabled={!client?.active_test}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className='py-3'>
                                            <Col>
                                                <p><strong className="azul">{t('prospect.table-header3a')}</strong> {t('prospect.table-header3b')}</p>
                                                <Select isMulti className="form-control form-control-verde" name="emotions2" onChange={selectChangeEmotions2} options={emotionsOptions.options} value={emotionsOptions.selected2} isDisabled={!client?.active_test}/>
                                                <br/>
                                                <Button variant="primary" onClick={() => setModalShow(true)}>
                                                    <i className="fas fa-search"></i> {t('prospect.feelings')}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className='py-3'>
                                            <Col>
                                                <p><strong className="azul">{t('prospect.table-header4a')}</strong> {t('prospect.table-header4b')}</p>
                                                <Select isMulti className="form-control form-control-verde" name="behaviors2" onChange={selectChangeBehaviors2} options={behaviorsOptions.options} value={behaviorsOptions.selected2} isDisabled={!client?.active_test}/>
                                                <br/>
                                                <Button variant="primary" onClick={() => setModalShowTwo(true)}>
                                                    <i className="fas fa-search"></i> {t('prospect.conducts')}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className='my-4'>
                                            <Col xs="12">
                                                <div className="mt-2 mt-md-0 text-start">
                                                    <Button variant='default' className='bg-modulo1' type="submit" onClick={handleSubmit2} disabled={!client?.active_test}>
                                                    <span className="text-start">{t('prospect.add1')}</span> <span className="text-end">{t('prospect.add2')}</span>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs='12' md='6'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t('prospect.who')}</th>
                                            <th>{t('prospect.prospect')}</th>
                                            <th>{t('prospect.feelings')}</th>
                                            <th>{t('prospect.conducts')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tests?.map((testrealizado, id)=>(
                                            testrealizado.me === false 
                                            ?
                                                <tr key={id}>
                                                    <td>
                                                        <div className="form-label mx-2 btn btn-primary py-2 px-3" onClick={client?.active_test ? () => deleteProspectTest(testrealizado._id) : <></>}><i className="fas fa-times"></i></div>
                                                    </td>
                                                    <td>{t('prospect.persons.'+testrealizado.who)}</td>
                                                    <td>
                                                        {testrealizado.prospect[0]
                                                            ?
                                                            <>
                                                                {testrealizado.prospect[0].toUpperCase() + testrealizado.prospect?.slice(1).toLowerCase()}
                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                    </td>
                                                    <td>
                                                        {testrealizado?.feelings?.map((feeling, key)=>(
                                                            <div key={key}>
                                                                {t('emotions.'+feeling.emotion)}
                                                            </div>
                                                        ))}
                                                    </td>
                                                    <td>
                                                        {testrealizado?.conducts?.map((conduct, key)=>(
                                                            <div key={key}>
                                                                {t('behaviors.'+conduct.behavior)}
                                                            </div>
                                                        ))}
                                                    </td>
                                                </tr>
                                            :
                                                <Fragment key={id}></Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                                <h4 className='verde'>{t('resume.virtues-to-train')}</h4>
                                <Accordion> 
                                {virtues2?.map((virtue, id)=>{
                                    return (
                                        virtue.virtue !== ''
                                        ?
                                            <Fragment key={id}>
                                                <Accordion.Item eventKey={id} key={id} className='virtudes-sincolor'> 
                                                    <Accordion.Header>{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {t('descriptions.'+virtue.virtue )}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Fragment>
                                        :
                                        <></>
                                    )
                                })}
                                </Accordion>
                            </Col>
                        </Row>
                        <Row className='my-4 botones-pasos'>
                        <Col className="d-flex justify-content-md-between justify-content-center flex-wrap">
                                        <Link to="/client/result">
                                            <Button className='boton-gris' variant='primary' type="submit">
                                                {t('conscience.initial')}
                                            </Button>
                                        </Link>
                                    
                                    
                                        <Link to="/client/test/conscience/weak">
                                            <Button className='boton-gris' variant='primary' type="submit">
                                                {t('conscience.virtues-to-train')}
                                            </Button>
                                        </Link>
                                    
                                        <Button className='boton-blanco' variant='primary' type="submit">
                                            {t('conscience.limiting-expectations')}
                                        </Button>
                                    
                                        <Link to="/client/test/conscience/mediumhard">
                                            <Button className='boton-gris' variant='primary' type="submit">
                                                {t('conscience.medium-strong-virtues')}
                                            </Button>
                                        </Link>
                                    
                                        <Link to="/client/test/conscience/mediumhard">
                                            <Button variant='primary' type="submit">
                                            <i className='fas fa-save'></i> {t('global.saveandgo')}
                                            </Button>
                                        </Link>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Virtues></Virtues>
            </Row>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('prospect.feelings')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {feelingDescription.map((feeling, index) => (
                                index <= (feelingDescription.length/2) ?
                                <><small>· {feeling}</small><br/></>
                                :
                                <></>
                            ))}
                        </Col>
                        <Col>
                            {feelingDescription.map((feeling, index) => (
                                index > (feelingDescription.length/2) ?
                                <><small>· {feeling}</small><br/></>
                                :
                                <></>
                            ))}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>{t('prospect.close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={modalShowTwo}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('prospect.conducts')}                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {conductDescription.map((conduct, index) => (
                                index <= (conductDescription.length/2) ?
                                <><small>· {conduct}</small><br/></>
                                :
                                <></>
                            ))}
                        </Col>
                        <Col>
                            {conductDescription.map((conduct, index) => (
                                index > (conductDescription.length/2) ?
                                <><small>· {conduct}</small><br/></>
                                :
                                <></>
                            ))}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalShowTwo(false)}>{t('prospect.close')}</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    </>
    )
}