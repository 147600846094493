import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Container, Row, Col, Button, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getAllGuide } from '../../../../actions/guideActions'
import { Link } from 'react-router-dom'

export default function Guide() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {guides} = useSelector(state=>state.guide)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getAllGuide())
    }, [dispatch])

    return (
        <>
            <Container fluid className='contenido modulo2'>
                <Row className='px-0 px-md-5'>
                    <Col xs="12" className='mx-auto'>
                        <div className="form p-2 p-md-4">
                            <div>
                                <Row>
                                    <Col className='px-4 d-flex'>
                                        <h2 className='verde'>{t('guide.title')}</h2>
                                        <Link to="/client/profile" className='position-fixed icono-home'>
                                            <i class="fas fa-home fa-2x"></i>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='px-4 pb-3'>
                                        <div className="alert auto alert-success text-center" role="alert">
                                            <h3 className='m-0 text-white'>{t('guide.guides')}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='px-4'>
                                        <p><strong>{t('guide.paragraph1')}</strong></p>
                                    </Col>
                                </Row>
                            </div>
                            <Card>
                                <Card.Body className='px-2 px-md-5'>
                                    <Row className='pt-3 pb-4'>
                                        {guides?.length > 0
                                            ?
                                                guides?.map((guide, iguide) => (
                                                    <Col key={iguide} xs={6} md={4}  className="py-3">
                                                        <Link className='d-block btn btn-primary boton-blanco' to={`/client/test/responsibility/explain/${guide.virtue}`}>{t('virtues.'+guide.virtue)}</Link>
                                                    </Col>
                                                ))
                                            :
                                                <>{t('guide.thereisnt')}</>
                                        }
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Row className='my-4 botones-pasos'>
                                <Col className="d-flex justify-content-md-between justify-content-center flex-wrap">
                                    
                                            <Link to="/client/test/responsibility/weeklyresult">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.weekly')}
                                                </Button>
                                            </Link>
                                    
                                            <Link to="/client/test/responsibility/evaluation">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.evaluation')}
                                                </Button>
                                            </Link>                                            
                                    
                                            <Link to="/client/test/responsibility/training">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.training')}
                                                </Button>
                                            </Link>

                                            <Button className='boton-gris' variant='primary' type="submit">
                                                {t('responsibility.historical')}
                                            </Button>
                                    
                                            <Link to="/client/test/responsibility/essential">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.essential')}
                                                </Button>
                                            </Link>
                                </Col>
                            </Row>   
                            <Row className='my-4 botones-pasos'>
                                <Col className="d-flex justify-content-center flex-wrap">
                                    <Link to="/client/test/responsibility/guide">
                                        <Button variant='primary guide' type="submit" disabled>
                                            {t('responsibility.guide')}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}