import React, { useEffect, useState} from 'react'
import {Container,Row,Col,Card, Accordion} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getInitialTest } from '../../actions/initialTestActions'
import { getUserDetail } from '../../actions/usersActions'
import { getAllTrainingTest } from '../../actions/trainingTestActions'
import { getAllWeeklyTest } from '../../actions/weeklyTestActions'
import { Link } from 'react-router-dom'
import { getPersonalTest } from '../../actions/personalTestActions'

export default function Profile() { 
	const { t } = useTranslation()
	const {client} = useAuth()
	const {test} = useSelector(state=>state.initialTest)
    const {personalTest} = useSelector(state=>state.personalTest)
	const {tests} = useSelector(state=>state.weeklyTest)
	const {user} = useSelector(state=>state.users)
    const {exercises} = useSelector(state=>state.trainingTest)
    const [exercisesCount, setExercisesCount] = useState(0)

	const dispatch = useDispatch()

	useEffect(() => {
		if(client?.id){
			dispatch(getUserDetail(client?.id))
			dispatch(getInitialTest(client?.id))
			dispatch(getAllTrainingTest(client?.id))
			dispatch(getAllWeeklyTest(client?.id))
			dispatch(getPersonalTest(client?.id))
		}
	}, [dispatch, client])

    useEffect(() => {
        if(exercises){
			setExercisesCount(exercises.filter(exercise => exercise.done !== true).length)
        }
    }, [exercises])

	return (
		<>
			<Container fluid className='contenido'>
				<Container className='mt-5'>
					<Row className='d-flex align-items-start'>
						<Col xs="12" sm="5" className='d-flex justify-content-start align-items-center pt-5 p-2'>
							<div className="avatar d-inline-block align-top justify-center float-left">
								{user?.image?.length>0
									?
										<img src={user.image} alt=''/>
									:
										<img src="/images/avatar.png" alt=''/>
								}
							</div>
							<div className='ms-5'>
								<h3>¡{t('profile.hello')} {user?.firstname}!</h3>
								<p>{t('profile.phrase')}</p>
								<Link to="/client/editprofile">
								<p><i className='fas fa-edit'></i> {t('profile.editprofile')}</p>
								</Link>
							</div>
						</Col>
						<Col xs="12" sm="3">
							<h4>&nbsp;</h4>
							{user?.active_test
										?
										<Link to="/client/resume">
											<Card className='mb-2 bg-oscuro'>
												<Card.Body className='py-1 d-flex align-items-center text-white'>
													<i className="fas fa-solid fa-star-of-life"></i>
													<h5 className='ms-2 mt-2'>
														{t('profile.profile')}
													</h5>
												</Card.Body>
											</Card>
										</Link>
										:
										<Link to="/client/resume2">
											<Card className='mb-2 bg-oscuro'>
												<Card.Body className='py-1 d-flex align-items-center text-white'>
													<i className="fas fa-solid fa-star-of-life"></i>
													<h5 className='ms-2 mt-2'>
														{t('profile.profiletoday')}
													</h5>
												</Card.Body>
											</Card>
										</Link>
							}
							
							{/* <Link to="/client/polarity">
								<Card className='mb-2'>
									<Card.Body className='py-1 d-flex align-items-center'>
										<img src={`/images/icono-polaridades.png`} width={50} alt=""/>
										<h5 className='ms-2 mt-2'>
											{t('profile.polarities')}
										</h5>
									</Card.Body>
								</Card>
							</Link> */}
							{user?.active_test
							?						
								<></>
							:
								<Link to="/client/test/responsibility/training">
									<Card className='mb-2 bg-oscuro sin-borde'>
										<Card.Body className='py-1 d-flex align-items-center text-white'>
											<i className="fas fa-solid fa-circle"></i>
											<h5 className='ms-2 mt-2'>
												{t('responsibility.training')}
											</h5>
										</Card.Body>
									</Card>
								</Link>
							}
						</Col>
						<Col xs="12" sm="2" className='mt-4'>
							<h4 >{t('profile.advance')}</h4>
							{user?.active_test
									?
										test && test[0]?.result
											?
												<Link to="/client/result">
													<Card className="mb-2 p-3 sin-borde">
														<h5 className={'text-center text-uppercase '+test[0]?.result+'-texto'}>
															{t(`initial.`+test[0]?.result)}
														</h5>
														<Card.Body className='pb-2 text-center'>
															<img src={`/images/iconos-estados/`+test[0]?.result+`.png`} className="img-fluid" alt="" width="94"/>
														</Card.Body>
													</Card>
												</Link>
											:
												<Card className="mb-2 p-3 sin-borde">
													<Card.Body className='pb-2 text-center'>
														{t('profile.thereisnt')}
													</Card.Body>
												</Card>
									:
										tests && tests[tests?.length-1]?.result
											?
												<Link to="/client/test/responsibility/weeklyresult">
													<Card className="mb-2 p-3 sin-borde">
														<h5 className={'text-center text-uppercase '+tests[tests?.length-1]?.result+'-texto'}>
															{t(`initial.`+tests[tests?.length-1]?.result)}
														</h5>
														<Card.Body className='pb-2 text-center'>
															<img src={`/images/iconos-estados/`+tests[tests?.length-1]?.result+`.png`} className="img-fluid" alt="" width="94"/>
														</Card.Body>
													</Card>
												</Link>
											:
												<Card className="mb-2 p-3 sin-borde">
													<Card.Body className='pb-2 text-center'>
														{t('profile.thereisntweekly')}
													</Card.Body>
												</Card>
							}
						</Col>
						<Col xs="12" sm="2" className='mt-4'>
							<h4 >{t('profile.remember')}</h4>
							<Card className="mb-2 p-2 sin-borde">
								<Card.Body className='pb-2'>
									<img src={`/images/icono-globo.png`} width={53} alt=""/>
									<h5>{t('profile.exercisesnotdone1')}{exercisesCount}{t('profile.exercisesnotdone2')}</h5>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
				<Container className='mt-5'>
					<Row>
						<Col>
							<h4 >{t('profile.your-way')}</h4>
						</Col>
					</Row>      
				</Container>
				<Container id="camino">
					<Row>
						<Col  className='p-2'>
							<Card className='p-4 bg-modulo1 sin-borde'>
								<Card.Body>
									<Row className='d-flex align-items-stretch superior'>
										<Col xs='12' className='d-flex align-items-start'>
											<div>
												<i className="fas fa-solid fa-star-of-life fa-4x text-white"></i>
											</div>
											<div className='tarjeta-test ms-4'>
												<h4>{t('profile.conscience')}</h4>
												<p>{t('profile.phrase-conscience')}</p>
												
											</div>
										</Col>
										<Col xs='12'>
											{user?.active_test === false
												?
												<>											
												<p className='text-center'><i className='fas fa-lock fa-4x text-white'></i></p>
												</>
												:
												<></>
											}
										</Col>
									</Row>
									<Row className="mt-5">
										
										<Accordion className='border-0'>
											<Accordion.Item eventKey={1} key={1} className="bloque-enlaces">
												<Accordion.Header>{t('conscience.linktests1')}</Accordion.Header>
												<Accordion.Body>
													{test && test[0]?.result
														?
															<Link to="/client/result">
																<p>{t('conscience.initial')}</p>
															</Link>
														:
															<Link to="/client/test/initial">
																<p>{t('conscience.initial')}</p>
															</Link>
													}
													<Link to="/client/test/conscience/weak">
														<p>{t('conscience.virtues-to-train')}</p>
													</Link>
													<Link to="/client/test/conscience/prospect">
														<p>{t('conscience.limiting-expectations')}</p>
													</Link>
													<Link to="/client/test/conscience/mediumhard">
														<p>{t('conscience.medium-strong-virtues')}</p>
													</Link>
													<Link to="/client/test/conscience/harmful">
														<p>{t('conscience.harmful-habits')}</p>
													</Link>
													<Link to="/client/resume">
														<p>{t('profile.profile')} {t('global.phase')} {t('profile.conscience')}</p>
													</Link>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</Row>
								</Card.Body>
							</Card>
						</Col> 
						<Col  className='p-2'>
							<Card className='p-4 bg-modulo2 sin-borde'>
								<Card.Body>
									<Row className='d-flex align-items-stretch superior'>
										<Col className='d-flex align-items-start'>
											<div>
												<i className="fas fa-solid fa-circle fa-4x text-white"></i>
											</div>
											<div className='tarjeta-test ms-4'>
												<h4>{t('profile.responsibility')}</h4>
												{t('profile.phrase-responsibility')}
											</div>
										</Col>
									</Row>
									<Row className="mt-5">
										<Accordion> 
											<Accordion.Item eventKey={1} key={1} className="bloque-enlaces">
												{user?.active_test === false
													?
														<>
															<Accordion.Header>{t('responsibility.linktests2')}</Accordion.Header>
															<Accordion.Body>
																<Link to="/client/test/responsibility/weekly">
																	<p>{t('responsibility.weekly')}</p>
																</Link>
																<Link to="/client/test/responsibility/evaluation">
																	<p>{t('responsibility.evaluation')}</p>
																</Link>
																<Link to="/client/test/responsibility/training">
																	<p>{t('responsibility.training')}</p>
																</Link>
																<Link to="/client/test/responsibility/historical">
																	<p>{t('responsibility.historical')}</p>
																</Link>
																<Link to="/client/test/responsibility/essential">
																	<p>{t('responsibility.essential')}</p>
																</Link>
																<Link to="/client/test/responsibility/guide">
																	<p>{t('responsibility.guide')}</p>
																</Link>
																<Link to="/client/test/responsibility/manifest">
																	<p>{t('responsibility.manifest')}</p>
																</Link>
															</Accordion.Body>
														</>
													:
														<>
															<p className='text-center'><i className='fas fa-lock fa-4x  text-white'></i></p>
														</>
												}
											</Accordion.Item>
										</Accordion>
									</Row>
								</Card.Body>
							</Card>
						</Col>
						<Col  className='p-2'>
							<Card className='p-4 bg-modulo3 sin-borde'>                        
								<Card.Body>
									<Row className='d-flex align-items-stretch superior'>
										<Col className='d-flex align-items-start'>
											<div>
												<i class="fas fa-solid fa-circle fa-4x text-white"></i>
												<i className="fas fa-solid fa-star-of-life text-white"></i>
											</div>
											<div className='tarjeta-test ms-4'>
												<h4>{t('profile.impact')}</h4>
												{t('profile.phrase-impact')}
											</div>
										</Col>
									</Row>
									<Row className="mt-5">
										<Accordion> 
											<Accordion.Item eventKey={1} key={1} className="bloque-enlaces">
												{user?.active_test === false
													?
														<>
															<Accordion.Header>{t('impact.linktests3')}</Accordion.Header>
															<Accordion.Body>
																{personalTest
																	?
																		<Link to="/client/test/impact/personalresume">
																			<p>{t('impact.personal')}</p>
																		</Link>
																	:
																		<Link to="/client/test/impact/personal">
																			<p>{t('impact.personal')}</p>
																		</Link>
																}
																<Link to="/client/test/impact/professional">
																	<p>{t('impact.professional')}</p>
																</Link>
																<Link to="/client/test/impact/couple">
																	<p>{t('impact.couple')}</p>
																</Link>
															</Accordion.Body>
														</>
													:
														<>
															<p className='text-center'><i className='fas fa-lock fa-4x  text-white'></i></p>
														</>
												}
											</Accordion.Item>
										</Accordion>
									</Row>
								</Card.Body>
							</Card>
						</Col>    
					</Row>
				</Container>
			</Container>
		</>
	)
}