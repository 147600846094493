import React,{useEffect, useState} from 'react'
import { Container, Row, Col, Card, Form, Button} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetail, editUser } from '../../actions/usersActions'
import { useNavigate,Link } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function Profile() { 
	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {client} = useAuth()
	const {user} = useSelector(state=>state.users)

    const [formState, setFormState] = useState({})
    const {username, address, email, firstname, phone, lastname, password, access, active_test, idUser} = formState

    const [newpassword, setNewpassword] = useState('')
    const [confpassword, setConfNewpassword] = useState('')
    const [match, setMatch] = useState(true)
    const [imageAux, setImage] = useState(null)

	useEffect(() => {
		if(client?.id){
			dispatch(getUserDetail(client?.id))
		}
  	}, [dispatch, client])

  	useEffect(() => {
		if(user){
			setFormState({
				username: user.username,
				address: user.address,
				email: user.email,
				firstname: user.firstname,
				phone: user.phone,
				lastname: user.lastname,
				password: '', 
				access: user.access,
				active_test: user.active_test,
				idUser: user._id
			})
			setImage(user.image)
		}
  	}, [user])

    const inputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const inputNewPassChange = (e) => {
        setNewpassword(e.target.value)
    }

    const inputConfNewPassChange = (e) => {
        setConfNewpassword(e.target.value)
    }

    useEffect(() => {
        if(newpassword.length > 5 && confpassword.length > 5){
            if(newpassword === confpassword){
                inputChange({target:{name: 'password', value: newpassword}})
                setMatch(true)
            }else{
                inputChange({target:{name: 'password', value: ''}})
                setMatch(false)
            }
        }else{
            inputChange({target:{name: 'password', value: ''}})
            setMatch(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newpassword, confpassword])

	const changeImage = (e) => {
		setImage(e.target.files[0])
	}

    const submit = async(e) => {
        e.preventDefault()

        dispatch(editUser(username, email, password, firstname, lastname, address, access, phone, imageAux, active_test, idUser))
        Swal.fire({
            showConfirmButton: true,
            icon: 'success',
            text: t('admin.user.edit'),
			confirmButtonText: t('admin.user.great')
        }).then((result) => {
			if (result.isConfirmed) {
				navigate('/client/profile')
			}
		})
    }

	return (
		<>
			<Container fluid className='contenido'>
				<Container className='mt-5'>					
					<Row className='d-flex align-items-start'>
						<Col className='col-12 col-md-6 pt-5 p-2 mx-auto'>                  
							<div className='ms-5'>
								<h3>¡{t('editprofile.hello')} {user?.firstname}!</h3>
								<p>{t('editprofile.phrase')}</p>
								<Card className='mb-2 p-5'>
									<Row>
										<Col className='d-flex justify-content-end'>
											<Link to="/client/profile">
												<i class="fas fa-home fa-2x"></i>
											</Link>
										</Col>
									</Row>
									<Card.Body>
										<Form onSubmit={submit}>
											<Form.Group>
												<Form.Label>{t('users.image')}</Form.Label><br/><br/>
												{ imageAux ? <img alt="Preview" height="60" src={typeof imageAux === 'string' ? imageAux : URL.createObjectURL(imageAux)} /> : null }<br/><br/>
												<input type="file" name="myImage" onChange={changeImage} /><br/><br/>
											</Form.Group>											
											<Form.Group>
												<Form.Label>{t('users.firstname')}</Form.Label>
												<Form.Control
													type='text'
													name='firstname' 
													value={formState.firstname || formState.firstnameEdit ||  ''}
													placeholder={t('users.firstname')}
													onChange={inputChange}
													required
												/>
											</Form.Group>
											<Form.Group>
												<Form.Label className="mt-3">{t('users.lastname')}</Form.Label>
												<Form.Control
													type='text'
													name='lastname' 
													value={formState.lastname || formState.lastnameEdit ||  ''}
													placeholder={t('users.lastname')}
													onChange={inputChange}
													required
												/>
											</Form.Group>
											<Form.Group>
												<Form.Label className="mt-3">{t('users.email')}</Form.Label>
												<Form.Control
													type='text'
													name='email' 
													value={formState.email || formState.emailEdit ||  ''}
													placeholder={t('users.email')}
													onChange={inputChange}
													required
												/>
											</Form.Group>
											<Form.Group>
												<Form.Label className="mt-3">{t('users.address')}</Form.Label>
												<Form.Control
													type='text'
													name='address' 
													value={formState.address || formState.addressEdit ||  ''}
													placeholder={t('users.address')}
													onChange={inputChange}
													required
												/>
											</Form.Group>
											<Form.Group>
												<Form.Label className="mt-3">{t('users.phone')}</Form.Label>
												<Form.Control
													type='text'
													name='phone' 
													value={formState.phone || formState.phoneEdit ||  ''}
													placeholder={t('users.phone')}
													onChange={inputChange}
													required
												/>
											</Form.Group>
											<Form.Group>
												<Form.Label className="mt-3">{t('users.newpassword')}</Form.Label>
												<Form.Control
													autoComplete="new-password"
													type='password'
													name='password' 
													value={newpassword}
													placeholder={t('users.newpassword')}
													onChange={inputNewPassChange}
													minLength='6'
												/>
												<Form.Label className="mt-3">{t('users.confirmnewpassword')}</Form.Label>
												<Form.Control
													type='password'
													name='passwordconf' 
													value={confpassword}
													placeholder={t('users.confirmpassword')}
													onChange={inputConfNewPassChange}
												/>
												{ match === false
												?
													<span className="text-danger">* {t('admin.user.passnotmatch')}</span>
												:
													<span></span>
												}
											</Form.Group>
											<Form.Group className="d-flex justify-content-center">
												<Button className="mt-4" type="submit" disabled={!match}>{t('admin.user.actualizar')}</Button>
											</Form.Group>
										</Form>
									</Card.Body>
								</Card>
							</div>
						</Col>
					</Row>
				</Container>
			</Container>
		</>
  	)
}