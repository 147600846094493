import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Container, Row, Col, Form, Button, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { sendWeeklyTest, getAllWeeklyTest } from '../../../../actions/weeklyTestActions'
import { NeverAlways } from '../../../../components/never-always'
import { LinesTest } from '../../../../components/linesTest'

export default function Weekly() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {client} = useAuth()
    const [step, setStep] = useState(1)
	const {tests} = useSelector(state=>state.weeklyTest)

    const [responsesTotal, setResponsesTotal] = useState([])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
		if(client?.id){
			dispatch(getAllWeeklyTest(client?.id))
		}
    }, [client, dispatch, t])

    const setResponse = (index, value) => {
        setResponsesTotal({
            ...responsesTotal, [index]: value
        })
    }

    const handleClick = async(e) => {
        e.preventDefault()
        if(step < 4){
            if(comprobarBloqueCompletado(step)){
                setStep(step+1)
                var element = document.querySelector("#weekly_test_header");
                element.scrollIntoView({ behavior: 'smooth', block: 'end'});
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'error',
                    text: 'Complete todas las preguntas para continuar.'
                })
            }
        }else{
            if(comprobarBloqueCompletado(step)){
                let responses = []
                for(var i=1;i<=20;i++){
                    responses.push(verradiovalue('q'+i))
                }
                dispatch(sendWeeklyTest(responses,client.id)).then(navigate('/client/test/responsibility/weeklyresult'))
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'error',
                    text: 'Complete todas las preguntas para continuar.'
                })
            }
        }
    }

    const comprobarBloqueCompletado = (step) => {
        var contador=0
        for(var i=1+(step-1)*5;i<=step*5;i++){
            if(verradiovalue('q'+i)>0){
                contador++
            }
        }
        if(contador>=5){
            return true
        }else{
            return false
        }
    }    

    const verradiovalue = (id) => {
        var i 
        var elemento = document.getElementsByName(id)        
        for (i = 0; i < elemento.length; i++){ 
           if (elemento[i].checked) {
            return elemento[i].value
          }
        }
    }

    return (
        <>
            <Container className='contenido modulo2'>
                <Row className='px-0 px-md-5'>
                    <Col className='px-0 px-md-5'>
                        <div className="form p-4" id="weekly_test">
                            <div id="weekly_test_header" className='mb-3'>
                                <Row>
                                    <Col>
                                        <h2>{t('weekly.title')}</h2>
                                        <p>{t('weekly.description')}</p>
                                    </Col>
                                    <Col className='text-end'>
		                                {tests?.length > 0
                                            ?
                                                <Link to="/client/test/responsibility/weeklyresult">
                                                    <Button>{t('weekly.toweeklyresult')}</Button>
                                                </Link>
                                            :
                                                <></>
                                        }
                                    </Col>
                                </Row>
                            </div>          
                            <Card>
                                <Card.Body>
                            {['health','success','company','effort'].map((steps,id) => (     

                                <div id={`inital_test_${steps}`} style={step === id+1 ? null : {display: "none"}} key={steps} >
                                    <h3 className='titulo-paso-test d-flex align-items-center'>
                                        <img
                                            src={`/images/initial_test_${id+1}.png`}
                                            className="d-inline-block align-top justify-center pe-3"
                                            alt=""
                                            width="80"
                                        />
                                        {t('weekly.'+steps)}
                                    </h3>
                                    {[1,2,3,4,5].map((question, indice) => (
                                        <Fragment key={indice}>
                                            <div key={`${steps+question}`} attr_key={`${steps+question}`}>
                                                <Row className='mt-5'>
                                                    <Col xs="12" lg="6">
                                                        {t('weekly.question'+(question+(id*5)))}
                                                    </Col>
                                                    <Col  xs="12" lg="6" className='d-flex flex-column'>
                                                        <NeverAlways/>
                                                        <Row className='text-center'>
                                                        {[1,2,3,4].map((number,key) => (
                                                            <Col key={key}>
                                                                <Form.Check className='form-check-inline' type="radio" onClick={() => setResponse((question+(id*5))-1, number)} name={`q${question+(id*5)}`} value={`${number}`}  key={`${steps+question+number}`}/>
                                                            </Col>
                                                        ))}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Fragment>
                                    ))}
                                </div>
        
                            ))}  
                                </Card.Body>
                            </Card>                       
                            {/* PIE Y BOTON */}
                            <Row id="weekly_test_footer">   
                            </Row>
                            <Row className='my-4 d-flex align-items-center'>
                                <Col xs="12" md="8" id="weekly_test_footer_bottom">
                                    <LinesTest position={step} cantidad={4} module='modulo2line' />
                                </Col>
                                <Col xs="12" md="4" id="weekly_test_footer_right">
                                    <div className="d-grid d-block gap-2 text-end mt-2 mt-md-0">
                                        <Button variant='primary' type="submit" onClick={handleClick}>
                                            {t('global.saveandgo')}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-start d-md-flex'>
                        {['health','success','company','effort'].map((steps,id) => (
                            <Fragment key={id} >
                                {(steps === 'health' && step >= 2) || (steps === 'success' && step >= 3) || (steps === 'company' && step >= 4) || (steps === 'effort' && step >= 5) ?
                                    <>
                                        <Card className="my-4 w-50 mx-2">
                                            <Card.Body>
                                                <div id={`inital_test_${steps}`} key={steps} >
                                                    <h3 className='titulo-paso-test d-flex align-items-center'>
                                                        <img
                                                            src={`/images/initial_test_${id+1}.png`}
                                                            className="d-inline-block align-top justify-center pe-3"
                                                            alt=""
                                                            width="80"
                                                        />
                                                        {t('weekly.'+steps)}
                                                    </h3>
                                                    {[1,2,3,4,5].map((question, indice) => (
                                                        <Fragment key={indice}>
                                                            <div key={`${steps+question}`} attr_key={`${steps+question}`}>
                                                                <Row className='mt-5'>
                                                                    <Col xs="10">
                                                                        <p>{t('weekly.question'+(question+(id*5)))}</p>
                                                                    </Col>
                                                                    <Col  xs="2" className='d-flex flex-column'>
                                                                        <Row className='text-center'>
                                                                            <Col>
                                                                                <input className="form-check-input form-check-inline" type="radio" name={`q${question+(id*5)}`} value={responsesTotal[question+(id*5)-1]} />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Fragment>
                                                    ))}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </>
                                    :
                                    <></>
                                }
                            </Fragment>
                        ))}
                    </Col>
                </Row>
            </Container>
        </>
    )
}